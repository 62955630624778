export interface SelectOptions {
    name: string;
    value: string;
}

export const countrySelectOptions: SelectOptions[] = [
    {
      name: 'Afghanistan',
      value: 'AF'
    },
    {
      name: 'Ägypten',
      value: 'EG'
    },
    {
      name: 'Albanien',
      value: 'AL'
    },
    {
      name: 'Algerien',
      value: 'DZ'
    },
    {
      name: 'Andorra',
      value: 'AD'
    },
    {
      name: 'Angola',
      value: 'AO'
    },
    {
      name: 'Antigua und Barbuda',
      value: 'AG'
    },
    {
      name: 'Äquatorialguinea',
      value: 'GQ'
    },
    {
      name: 'Argentinien',
      value: 'AR'
    },
    {
      name: 'Armenien',
      value: 'AM'
    },
    {
      name: 'Aserbaidschan',
      value: 'AZ'
    },
    {
      name: 'Äthiopien',
      value: 'ET'
    },
    {
      name: 'Australien',
      value: 'AU'
    },
    {
      name: 'Bahamas',
      value: 'BS'
    },
    {
      name: 'Bahrain',
      value: 'BH'
    },
    {
      name: 'Bangladesch',
      value: 'BD'
    },
    {
      name: 'Barbados',
      value: 'BB'
    },
    {
      name: 'Belarus',
      value: 'BY'
    },
    {
      name: 'Belgien',
      value: 'BE'
    },
    {
      name: 'Belize',
      value: 'BZ'
    },
    {
      name: 'Benin',
      value: 'BJ'
    },
    {
      name: 'Bhutan',
      value: 'BT'
    },
    {
      name: 'Bolivien',
      value: 'BO'
    },
    {
      name: 'Bosnien und Herzegowina',
      value: 'BA'
    },
    {
      name: 'Botsuana',
      value: 'BW'
    },
    {
      name: 'Brasilien',
      value: 'BR'
    },
    {
      name: 'Brunei Darussalam',
      value: 'BN'
    },
    {
      name: 'Bulgarien',
      value: 'BG'
    },
    {
      name: 'Burkina Faso',
      value: 'BF'
    },
    {
      name: 'Burundi',
      value: 'BI'
    },
    {
      name: 'Cabo Verde',
      value: 'CV'
    },
    {
      name: 'Chile',
      value: 'CL'
    },
    {
      name: 'China',
      value: 'CN'
    },
    {
      name: 'Cookinseln',
      value: 'CK'
    },
    {
      name: 'Costa Rica',
      value: 'CR'
    },
    {
      name: 'Côte d\'Ivoire',
      value: 'CI'
    },
    {
      name: 'Dänemark',
      value: 'DK'
    },
    {
      name: 'Deutschland',
      value: 'DE'
    },
    {
      name: 'Dominica',
      value: 'DM'
    },
    {
      name: 'Dominikanische Republik',
      value: 'DO'
    },
    {
      name: 'Dschibuti',
      value: 'DJ'
    },
    {
      name: 'Ecuador',
      value: 'EC'
    },
    {
      name: 'El Salvador',
      value: 'SV'
    },
    {
      name: 'Eritrea',
      value: 'ER'
    },
    {
      name: 'Estland',
      value: 'EE'
    },
    {
      name: 'Eswatini',
      value: 'SZ'
    },
    {
      name: 'Fidschi',
      value: 'FJ'
    },
    {
      name: 'Finnland',
      value: 'FI'
    },
    {
      name: 'Frankreich',
      value: 'FR'
    },
    {
      name: 'Gabun',
      value: 'GA'
    },
    {
      name: 'Gambia',
      value: 'GM'
    },
    {
      name: 'Georgien',
      value: 'GE'
    },
    {
      name: 'Ghana',
      value: 'GH'
    },
    {
      name: 'Grenada',
      value: 'GD'
    },
    {
      name: 'Griechenland',
      value: 'GR'
    },
    {
      name: 'Großbritannien',
      value: 'GB'
    },
    {
      name: 'Guatemala',
      value: 'GT'
    },
    {
      name: 'Guinea',
      value: 'GN'
    },
    {
      name: 'Guinea-Bissau',
      value: 'GW'
    },
    {
      name: 'Guyana',
      value: 'GY'
    },
    {
      name: 'Haiti',
      value: 'HT'
    },
    {
      name: 'Honduras',
      value: 'HN'
    },
    {
      name: 'Indien',
      value: 'IN'
    },
    {
      name: 'Indonesien',
      value: 'ID'
    },
    {
      name: 'Irak',
      value: 'IQ'
    },
    {
      name: 'Iran',
      value: 'IR'
    },
    {
      name: 'Irland',
      value: 'IE'
    },
    {
      name: 'Island',
      value: 'IS'
    },
    {
      name: 'Israel',
      value: 'IL'
    },
    {
      name: 'Italien',
      value: 'IT'
    },
    {
      name: 'Jamaika',
      value: 'JM'
    },
    {
      name: 'Japan',
      value: 'JP'
    },
    {
      name: 'Jemen',
      value: 'YE'
    },
    {
      name: 'Jordanien',
      value: 'JO'
    },
    {
      name: 'Kambodscha',
      value: 'KH'
    },
    {
      name: 'Kamerun',
      value: 'CM'
    },
    {
      name: 'Kanada',
      value: 'CA'
    },
    {
      name: 'Kasachstan',
      value: 'KZ'
    },
    {
      name: 'Katar',
      value: 'QA'
    },
    {
      name: 'Kenia',
      value: 'KE'
    },
    {
      name: 'Kirgisistan',
      value: 'KG'
    },
    {
      name: 'Kiribati',
      value: 'KI'
    },
    {
      name: 'Kolumbien',
      value: 'CO'
    },
    {
      name: 'Komoren',
      value: 'KM'
    },
    {
      name: 'Kongo',
      value: 'CG'
    },
    {
      name: 'Kongo, Demokratische Republik',
      value: 'CD'
    },
    {
      name: 'Korea, Demokratische Volksrepublik',
      value: 'KP'
    },
    {
      name: 'Korea, Republik',
      value: 'KR'
    },
    {
      name: 'Kosovo',
      value: ''
    },
    {
      name: 'Kroatien',
      value: 'HR'
    },
    {
      name: 'Kuba',
      value: 'CU'
    },
    {
      name: 'Kuwait',
      value: 'KW'
    },
    {
      name: 'Laos',
      value: 'LA'
    },
    {
      name: 'Lesotho',
      value: 'LS'
    },
    {
      name: 'Lettland',
      value: 'LV'
    },
    {
      name: 'Libanon',
      value: 'LB'
    },
    {
      name: 'Liberia',
      value: 'LR'
    },
    {
      name: 'Libyen',
      value: 'LY'
    },
    {
      name: 'Liechtenstein',
      value: 'LI'
    },
    {
      name: 'Litauen',
      value: 'LT'
    },
    {
      name: 'Luxemburg',
      value: 'LU'
    },
    {
      name: 'Madagaskar',
      value: 'MG'
    },
    {
      name: 'Malawi',
      value: 'MW'
    },
    {
      name: 'Malaysia',
      value: 'MY'
    },
    {
      name: 'Malediven',
      value: 'MV'
    },
    {
      name: 'Mali',
      value: 'ML'
    },
    {
      name: 'Malta',
      value: 'MT'
    },
    {
      name: 'Marokko',
      value: 'MA'
    },
    {
      name: 'Marshallinseln',
      value: 'MH'
    },
    {
      name: 'Mauretanien',
      value: 'MR'
    },
    {
      name: 'Mauritius',
      value: 'MU'
    },
    {
      name: 'Mexiko',
      value: 'MX'
    },
    {
      name: 'Mikronesien',
      value: 'FM'
    },
    {
      name: 'Moldau',
      value: 'MD'
    },
    {
      name: 'Monaco',
      value: 'MC'
    },
    {
      name: 'Mongolei',
      value: 'MN'
    },
    {
      name: 'Montenegro',
      value: 'ME'
    },
    {
      name: 'Mosambik',
      value: 'MZ'
    },
    {
      name: 'Myanmar',
      value: 'MM'
    },
    {
      name: 'Namibia',
      value: 'NA'
    },
    {
      name: 'Nauru',
      value: 'NR'
    },
    {
      name: 'Nepal',
      value: 'NP'
    },
    {
      name: 'Neuseeland',
      value: 'NZ'
    },
    {
      name: 'Nicaragua',
      value: 'NI'
    },
    {
      name: 'Niederlande',
      value: 'NL'
    },
    {
      name: 'Niger',
      value: 'NE'
    },
    {
      name: 'Nigeria',
      value: 'NG'
    },
    {
      name: 'Nordmazedonien',
      value: 'MK'
    },
    {
      name: 'Norwegen',
      value: 'NO'
    },
    {
      name: 'Oman',
      value: 'OM'
    },
    {
      name: 'Österreich',
      value: 'AT'
    },
    {
      name: 'Pakistan',
      value: 'PK'
    },
    {
      name: 'Palau',
      value: 'PW'
    },
    {
      name: 'Panama',
      value: 'PA'
    },
    {
      name: 'Papua-Neuguinea',
      value: 'PG'
    },
    {
      name: 'Paraguay',
      value: 'PY'
    },
    {
      name: 'Peru',
      value: 'PE'
    },
    {
      name: 'Philippinen',
      value: 'PH'
    },
    {
      name: 'Polen',
      value: 'PL'
    },
    {
      name: 'Portugal',
      value: 'PT'
    },
    {
      name: 'Ruanda',
      value: 'RW'
    },
    {
      name: 'Rumänien',
      value: 'RO'
    },
    {
      name: 'Russische Föderation',
      value: 'RU'
    },
    {
      name: 'Salomonen',
      value: 'SB'
    },
    {
      name: 'Sambia',
      value: 'ZM'
    },
    {
      name: 'Samoa',
      value: 'WS'
    },
    {
      name: 'San Marino',
      value: 'SM'
    },
    {
      name: 'São Tomé und Príncipe',
      value: 'ST'
    },
    {
      name: 'Saudi-Arabien',
      value: 'SA'
    },
    {
      name: 'Schweden',
      value: 'SE'
    },
    {
      name: 'Schweiz',
      value: 'CH'
    },
    {
      name: 'Senegal',
      value: 'SN'
    },
    {
      name: 'Serbien',
      value: 'RS'
    },
    {
      name: 'Seychellen',
      value: 'SC'
    },
    {
      name: 'Sierra Leone',
      value: 'SL'
    },
    {
      name: 'Simbabwe',
      value: 'ZW'
    },
    {
      name: 'Singapur',
      value: 'SG'
    },
    {
      name: 'Slowakei',
      value: 'SK'
    },
    {
      name: 'Slowenien',
      value: 'SI'
    },
    {
      name: 'Somalia',
      value: 'SO'
    },
    {
      name: 'Spanien',
      value: 'ES'
    },
    {
      name: 'Sri Lanka',
      value: 'LK'
    },
    {
      name: 'St. Kitts und Nevis',
      value: 'KN'
    },
    {
      name: 'St. Lucia',
      value: 'LC'
    },
    {
      name: 'St. Vincent und die Grenadinen',
      value: 'VC'
    },
    {
      name: 'Südafrika',
      value: 'ZA'
    },
    {
      name: 'Sudan',
      value: 'SD'
    },
    {
      name: 'Südsudan',
      value: 'SS'
    },
    {
      name: 'Suriname',
      value: 'SR'
    },
    {
      name: 'Syrien',
      value: 'SY'
    },
    {
      name: 'Tadschikistan',
      value: 'TJ'
    },
    {
      name: 'Taiwan',
      value: 'TW'
    },
    {
      name: 'Tansania',
      value: 'TZ'
    },
    {
      name: 'Thailand',
      value: 'TH'
    },
    {
      name: 'Timor-Leste',
      value: 'TL'
    },
    {
      name: 'Togo',
      value: 'TG'
    },
    {
      name: 'Tonga',
      value: 'TO'
    },
    {
      name: 'Trinidad und Tobago',
      value: 'TT'
    },
    {
      name: 'Tschad',
      value: 'TD'
    },
    {
      name: 'Tschechische Republik',
      value: 'CZ'
    },
    {
      name: 'Tunesien',
      value: 'TN'
    },
    {
      name: 'Türkei',
      value: 'TR'
    },
    {
      name: 'Turkmenistan',
      value: 'TM'
    },
    {
      name: 'Tuvalu',
      value: 'TV'
    },
    {
      name: 'Uganda',
      value: 'UG'
    },
    {
      name: 'Ukraine',
      value: 'UA'
    },
    {
      name: 'Ungarn',
      value: 'HU'
    },
    {
      name: 'Uruguay',
      value: 'UY'
    },
    {
      name: 'Usbekistan',
      value: 'UZ'
    },
    {
      name: 'Vanuatu',
      value: 'VU'
    },
    {
      name: 'Vatikanstadt',
      value: 'VA'
    },
    {
      name: 'Venezuela',
      value: 'VE'
    },
    {
      name: 'Vereinigte Arabische Emirate',
      value: 'AE'
    },
    {
      name: 'Vereinigte Staaten',
      value: 'US'
    },
    {
      name: 'Vietnam',
      value: 'VN'
    },
    {
      name: 'Zentralafrikanische Republik',
      value: 'CF'
    },
    {
      name: 'Zypern',
      value: 'CY'
    }
  ];
  