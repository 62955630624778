import { Pipe, PipeTransform } from "@angular/core";
import { stringToVoucherCode } from "../util/string-voucher-code.util";

@Pipe({
  name: "voucherCode",
})
export class VoucherCodePipe implements PipeTransform {
  transform(
    value: string,
    prefix = "",
    steps = 4,
    lengthOfSubString = 4
  ): string {
    return stringToVoucherCode(value, prefix, steps, lengthOfSubString);
  }
}
