interface Tax {
    id: number;
    total: string;
    subtotal: string;
}

interface ProductItem {
    id: number;
    name: string;
    product_id: number;
    variation_id: number;
    quantity: number;
    tax_class: string;
    subtotal: string;
    subtotal_tax: string;
    total: string;
    total_tax: string;
    taxes: Tax[];
    meta_data: any[];
    sku: string;
    price: number;
    parent_name?: any;
}

interface CouponItem {    
    id: number;
    code: string;
    discount: string;
    discount_tax: string;
    meta_data: any[];
}

export enum OrderStatus {
    PENDING = 'pending', 
    PROCESSING = 'processing', 
    ONHOLD = 'on-hold', 
    COMPLETED = 'completed', 
    CANCELLED = 'cancelled', 
    REFUNDED = 'refunded', 
    FAILED = 'failed', 
    TRASH = 'trash'
}

export interface AbstracOrder {
    id: number;
    status: OrderStatus;
    total: number;
    date_created: Date;
    date_modified: Date;
    date_completed?: Date;
    date_paid?: Date;
    number?: string;
    product_items?: ProductItem[];
    coupon_items: CouponItem[];
    invoice_url: string;
    shipping_url: string;
}

