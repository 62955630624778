<h2 mat-dialog-title>Checkout</h2>
<mat-dialog-content>
  <form
    class="p-4 bg-card shadow rounded overflow-hidden"
    [formGroup]="verticalStepperForm"
  >
    <mat-horizontal-stepper
      [linear]="true"
      labelPosition="bottom"
      #verticalStepper
    >
      <mat-step
        [formGroupName]="'step1'"
        [stepControl]="verticalStepperForm.get('step1')"
        #verticalStepperStep1
      >
        <ng-template matStepLabel>Rechnungsangaben</ng-template>
        <p class="my-6 font-medium">
          Bitte überprüfe die Daten und ergänze die notwendigen Informationen.
        </p>
        <div class="flex">
          <mat-form-field class="flex-auto">
            <mat-label>Firma</mat-label>
            <input matInput [formControlName]="'company'" required />
            <mat-icon matSuffix [svgIcon]="'business'"></mat-icon>
          </mat-form-field>
        </div>
        <div class="flex flex-col gt-xs:flex-row">
          <mat-form-field class="flex-auto gt-xs:pr-3">
            <mat-label>Vorname</mat-label>
            <input matInput [formControlName]="'firstName'" required />
            <mat-icon matSuffix [svgIcon]="'account_circle'"></mat-icon>
          </mat-form-field>
          <mat-form-field class="flex-auto gt-xs:pl-3">
            <mat-label>Nachname</mat-label>
            <input matInput [formControlName]="'lastName'" required />
            <mat-icon matSuffix [svgIcon]="'account_circle'"></mat-icon>
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field class="treo-mat-textarea flex-auto">
            <mat-label>Straße und Hausnummer</mat-label>
            <input matInput [formControlName]="'street1'" required />
            <mat-icon matSuffix [svgIcon]="'room'"></mat-icon>
          </mat-form-field>
        </div>

        <div class="flex flex-col gt-md:flex-row">
          <mat-form-field class="flex-auto gt-md:pr-3">
            <mat-label>Stadt</mat-label>
            <input matInput [formControlName]="'city'" required />
            <mat-icon matSuffix [svgIcon]="'landscape'"></mat-icon>
          </mat-form-field>
          <mat-form-field class="flex-auto gt-md:pl-3">
            <mat-label>Land</mat-label>
            <mat-select [formControlName]="'country'" required>
              <mat-option
                *ngFor="let item of countrySelectList"
                [value]="item.value"
                >{{ item.name }}</mat-option
              >
            </mat-select>
            <mat-icon matSuffix [svgIcon]="'landscape'"></mat-icon>
          </mat-form-field>
        </div>

        <div class="flex flex-col gt-md:flex-row">
          <mat-form-field class="flex-auto gt-md:pr-3">
            <mat-label>PLZ</mat-label>
            <input
              matInput
              maxlength="5"
              [formControlName]="'postcode'"
              required
              #postalCode
            />
            <mat-hint align="end">{{ postalCode.value.length }} / 5</mat-hint>
            <mat-icon matSuffix [svgIcon]="'mail'"></mat-icon>
          </mat-form-field>
          <mat-form-field class="flex-auto gt-md:pl-3">
            <mat-label>E-Mail Adresse</mat-label>
            <input matInput [formControlName]="'email'" required />
            <mat-icon matPrefix [svgIcon]="'mail'"></mat-icon>
          </mat-form-field>
        </div>
        <div class="flex justify-end">
          <button
            class="px-8"
            mat-flat-button
            [color]="'primary'"
            [disabled]="verticalStepperStep1.stepControl.invalid"
            type="button"
            matStepperNext
            (click)="
              dataAreTheSameValueChange(
                verticalStepperForm.get('step2.dataAreTheSame').value
              )
            "
          >
            Weiter
          </button>
        </div>
      </mat-step>

      <mat-step
        [formGroupName]="'step2'"
        [stepControl]="verticalStepperForm.get('step2')"
        #verticalStepperStep2
      >
        <ng-template matStepLabel>Lieferungsangaben</ng-template>
        <p class="my-6 font-medium">
          Bitte überprüfe die Daten und ergänze die notwendigen Informationen.
        </p>
        <div class="flex flex-row space-x-2 justify-center items-center my-2">
          <p>Daten sind mit Rechnungsdaten identisch</p>
          <mat-slide-toggle [formControlName]="'dataAreTheSame'">
          </mat-slide-toggle>
        </div>
        <div class="flex">
          <mat-form-field class="flex-auto">
            <mat-label>Firma</mat-label>
            <input matInput [formControlName]="'company'" required />
            <mat-icon matSuffix [svgIcon]="'business'"></mat-icon>
          </mat-form-field>
        </div>
        <div class="flex flex-col gt-xs:flex-row">
          <mat-form-field class="flex-auto gt-xs:pr-3">
            <mat-label>Vorname</mat-label>
            <input matInput [formControlName]="'firstName'" required />
            <mat-icon matSuffix [svgIcon]="'account_circle'"></mat-icon>
          </mat-form-field>
          <mat-form-field class="flex-auto gt-xs:pl-3">
            <mat-label>Nachname</mat-label>
            <input matInput [formControlName]="'lastName'" required />
            <mat-icon matSuffix [svgIcon]="'account_circle'"></mat-icon>
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field class="treo-mat-textarea flex-auto">
            <mat-label>Straße und Hausnummer</mat-label>
            <input matInput [formControlName]="'street1'" required />
            <mat-icon matSuffix [svgIcon]="'room'"></mat-icon>
          </mat-form-field>
        </div>

        <div class="flex flex-col gt-md:flex-row">
          <mat-form-field class="flex-auto gt-md:pr-3">
            <mat-label>Stadt</mat-label>
            <input matInput [formControlName]="'city'" required />
            <mat-icon matSuffix [svgIcon]="'landscape'"></mat-icon>
          </mat-form-field>
          <mat-form-field class="flex-auto gt-md:pl-3">
            <mat-label>Land</mat-label>
            <mat-select [formControlName]="'country'" required>
              <mat-option
                *ngFor="let item of countrySelectList"
                [value]="item.value"
                >{{ item.name }}</mat-option
              >
            </mat-select>
            <mat-icon matSuffix [svgIcon]="'landscape'"></mat-icon>
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field class="flex-auto">
            <mat-label>PLZ</mat-label>
            <input
              matInput
              maxlength="5"
              [formControlName]="'postcode'"
              required
              #postalCode
            />
            <mat-hint align="end">{{ postalCode.value.length }} / 5</mat-hint>
            <mat-icon matSuffix [svgIcon]="'mail'"></mat-icon>
          </mat-form-field>
        </div>
        <div class="flex justify-end">
          <button
            class="px-8 mr-2"
            mat-flat-button
            [color]="'accent'"
            type="button"
            matStepperPrevious
          >
            Zurück
          </button>
          <button
            class="px-8"
            mat-flat-button
            [color]="'primary'"
            [disabled]="verticalStepperStep2.stepControl.invalid"
            type="button"
            matStepperNext
          >
            Weiter
          </button>
        </div>
      </mat-step>

      <mat-step
        [formGroupName]="'step3'"
        [stepControl]="verticalStepperForm.get('step3')"
      >
        <ng-template matStepLabel>Zahlungsart</ng-template>
        <p class="my-6 font-medium">Bitte wähle deine Zahlungsmethode*</p>
        <mat-radio-group
          class="flex flex-col my-3"
          [formControlName]="'payOption'"
        >
          <mat-radio-button class="m-1" value="payOnBilling">
            <mat-icon [svgIcon]="'mail'"></mat-icon> Bezahlen per Rechnung
          </mat-radio-button>
        </mat-radio-group>
        <div class="flex justify-end mt-8">
          <button
            class="px-8 mr-2"
            mat-flat-button
            [color]="'accent'"
            type="button"
            matStepperPrevious
          >
            Zurück
          </button>
          <button
            class="px-8"
            mat-flat-button
            [color]="'primary'"
            type="button"
            matStepperNext
          >
            Weiter
          </button>
        </div>
      </mat-step>

      <mat-step
        [formGroupName]="'step4'"
        [stepControl]="verticalStepperForm.get('step4')"
      >
        <ng-template matStepLabel>Überprüfen & Bestätigen</ng-template>
        <p class="my-6 font-medium">Bitte überprüfe nochmal alle Angaben</p>
        <div class="my-6">
          Zahlungart: {{ getPayMethodString(fromData.step3.payOption) }}
        </div>
        <div class="flex flex-row flex-wrap">
          <div class="flex-auto flex-col flex-wrap">
            <p><b>Lieferungsangaben</b></p>
            <div>{{ fromData.step2.company }}</div>
            <div>
              {{ fromData.step2.firstName }} {{ fromData.step2.lastName }}
            </div>
            <div>{{ fromData.step2.street1 }}</div>
            <div>
              {{ fromData.step2.postcode }} {{ fromData.step2.city }}
              {{ fromData.step2.country }}
            </div>
          </div>
          <div class="flex-auto flex-col flex-wrap">
            <p><b>Rechnungsangaben</b></p>
            <div>{{ fromData.step1.company }}</div>
            <div>
              {{ fromData.step1.firstName }} {{ fromData.step1.lastName }}
            </div>
            <div>{{ fromData.step1.street1 }}</div>
            <div>
              {{ fromData.step1.postcode }} {{ fromData.step1.city }}
              {{ fromData.step1.country }}
            </div>
            <div>{{ fromData.step1.email }}</div>
          </div>
        </div>
        <div class="my-8"></div>
        <div
          class="flex flex-row border-gray-50 border-2"
          *ngFor="let cartProductItem of cartProductList; let ind = index"
        >
          <img
            [src]="cartProductItem.imageSrc"
            *ngIf="!hasProductItemNotAnImage(cartProductItem)"
            class="product-img h-full mr-1"
          />
          <div
            class="flex content-center justify-center mr-1 product-icon"
            *ngIf="hasProductItemNotAnImage(cartProductItem)"
          >
            <mat-icon
              class="self-center justify-self-center"
              svgIcon="shopping_bag"
            ></mat-icon>
          </div>
          <div class="flex-1 mx-1 flex-col flex-wrap">
            <p>
              <b>{{ cartProductItem.name }}</b>
            </p>
            <div class="flex-auto">
              {{ cartProductItem.amount }} x
              {{ cartProductItem.price | number : "1.2-2" }}€
            </div>
          </div>
          <span class="ml-1 items-center justify-end text-3xl"
            >{{
              cartProductItem.amount * cartProductItem.price | number : "1.2-2"
            }}€</span
          >
        </div>
        <div class="mt-8 flex items-start justify-end text-3xl">
          Zwischensumme:
          {{ (summaryPriceWithoutTax | currency : "EUR") || "€0,00" }}
        </div>
        <div
          class="mt-8 flex items-start justify-end text-3xl"
          *ngIf="shippingCost > 0"
        >
          Versandkosten: {{ (shippingCost | currency : "EUR") || "€0,00" }}
        </div>
        <div class="mt-8 flex items-start justify-end text-3xl">
          zzgl. Mehrwertsteuer (19%):
          {{ (taxPrice | currency : "EUR") || "€0,00" }}
        </div>
        <div class="mt-8 flex items-start justify-end text-5xl font-extrabold">
          Gesamtbetrag:
          {{ (summaryPriceWithTax | currency : "EUR") || "€0,00" }}
        </div>
        <hr />
        <div class="my-4 flex flex-row justify-between">
          <div class="flex-auto text-4xl font-semibold">Coupon:</div>
          <div class="flex flex-col items-end">
            <mat-form-field class="flex-auto">
              <input matInput [formControlName]="'coupon'" name="coupon" />
              <mat-icon matSuffix [svgIcon]="'card_giftcard'"></mat-icon>
            </mat-form-field>
            <button
              mat-flat-button
              class="flex-auto"
              [color]="'primary'"
              (click)="getCoupon()"
              type="button"
            >
              Coupon einlösen
            </button>
          </div>
        </div>
        <div class="my-4 flex flex-row justify-end items-end">
          <mat-checkbox formControlName="saveMetaData"
            >Daten im Profil abspeichern.</mat-checkbox
          >
        </div>
        <div class="flex justify-end mt-8">
          <button
            class="px-8 mr-2"
            mat-flat-button
            [color]="'accent'"
            type="button"
            matStepperPrevious
          >
            Zurück
          </button>
          <button
            class="px-8"
            mat-flat-button
            [color]="'primary'"
            type="reset"
            (click)="sendOrder()"
            [disabled]="!hasMerchantRights"
          >
            Kostenpflichtig bestellen
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Abbrechen</button>
</mat-dialog-actions>
