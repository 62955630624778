<div mat-dialog-title class="flex flex-row justify-between items-center w-full">
    <span class="text-xl font-semibold">Warenkorb</span>

    <button class="checkout xs:hidden" 
        color="primary" 
        mat-raised-button 
        [disabled]="!hasCartEntrie"
        matTooltip="Checkout" 
        (click)="openCheckoutDialog()">
        Checkout
    </button>

    <button class="checkout hidden xs:inline" 
        color="primary" 
        mat-raised-button 
        [disabled]="!hasCartEntrie"
        (click)="openCheckoutPage()"
        matTooltip="Checkout">
        Checkout
    </button> 
</div>
<div mat-dialog-content class="max-content">
    <!-- cart -->
    <ng-container *ngFor="let cartProduct of cartProductList$ | async">

        <div class="flex flex-col">
            <div class="hidden py-1 cart xs:flex xs:flex-row xs:justify-between">

                <!-- Message without a link -->
                <span>
                    <!-- Icon -->
                    <mat-icon class="icon"
                                *ngIf="hasProductItemNotAnImage(cartProduct)"
                                svgIcon="shopping_bag">
                    </mat-icon>
    
                    <!-- Image -->
                    <img class="image max-xs-image h-auto"
                            *ngIf="!hasProductItemNotAnImage(cartProduct)"
                            [src]="cartProduct.imageSrc">
    
                    <!-- Title, description & time -->
                    <div class="cart-content">
                        <div class="title">{{ cartProduct.name }}</div>
                        <div class="description">
                            <p>à  {{(cartProduct.price | currency: 'EUR') || '€0,00'}} = <b>{{((cartProduct.price * cartProduct.amount) | currency: 'EUR') || '€0,00'}}</b></p>
                            <mat-button-toggle-group name="fontStyle" (valueChange)="changeButtonCollection($event, cartProduct)" *ngIf="cartProduct.type === 'PRODUCT'">
                                <mat-button-toggle value="remove">
                                    <mat-icon [svgIcon]="'remove_circle'"></mat-icon>
                                </mat-button-toggle>
                                <mat-button-toggle value="" disabled="true">{{cartProduct.amount}}</mat-button-toggle>
                                <mat-button-toggle value="add">
                                    <mat-icon [svgIcon]="'add_circle'"></mat-icon>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </span>
    
                <!-- Actions -->
                <div class="actions">
                    <button class="remove"
                            mat-icon-button
                            (click)="removeCartProduct(cartProduct)" 
                            [matTooltip]="'Remove'">
                        <mat-icon [svgIcon]="'close'"></mat-icon>
                    </button>
                </div>
    
            </div>
    
            <div class="cart flex flex-row justify-between xs:p-0 xs:hidden">
    
                <!-- Message without a link -->
                <span class="flex flex-row justify-between items-center">
                    <!-- Icon -->
                    <mat-icon class="icon max-w-xs h-auto pr-2"
                                *ngIf="hasProductItemNotAnImage(cartProduct)"
                                svgIcon="shopping_bag">
                    </mat-icon>
    
                    <!-- Image -->
                    <img class="image max-w-xs h-auto px-2"
                            *ngIf="!hasProductItemNotAnImage(cartProduct)"
                            [src]="cartProduct.imageSrc">
    
                    <!-- Title, description & time -->
                    <div class="cart-content pl-2">
                        <div class="title">{{ cartProduct.name }}</div>
                        <div class="description">
                            <p>à  {{(cartProduct.price | currency: 'EUR') || '€0,00'}} = <b>{{((cartProduct.price * cartProduct.amount) | currency: 'EUR') || '€0,00'}}</b></p>
                            <mat-button-toggle-group name="fontStyle" (valueChange)="changeButtonCollection($event, cartProduct)" *ngIf="cartProduct.type === 'PRODUCT'">
                                <mat-button-toggle value="remove">
                                    <mat-icon [svgIcon]="'remove_circle'"></mat-icon>
                                </mat-button-toggle>
                                <mat-button-toggle value="" disabled="true">{{cartProduct.amount}}</mat-button-toggle>
                                <mat-button-toggle value="add">
                                    <mat-icon [svgIcon]="'add_circle'"></mat-icon>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </span>
    
                <!-- Actions -->
                <div class="actions">
                    <button class="remove"
                            mat-icon-button
                            (click)="removeCartProduct(cartProduct)" 
                            [matTooltip]="'Remove'">
                        <mat-icon [svgIcon]="'close'"></mat-icon>
                    </button>
                </div>
    
            </div>

            <hr>
        </div>
    </ng-container>

    <ng-container *ngIf="!hasCartEntrie">
        <div class="empty">
            <mat-icon [svgIcon]="'shopping_cart'"></mat-icon>
            <span>Kein Produkt im Warenkorb</span>
        </div>
    </ng-container>
</div>
<div mat-dialog-actions class="h-auto flex flex-row items-end justify-end">
    <button mat-button mat-dialog-close>Schließen</button>
</div>