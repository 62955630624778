import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AuthService } from "app/core/auth/auth.service";
import { ShopService } from "../shop.service";
import { SpinnerService } from "app/shared/spinner/spinner.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CheckOut } from "../check-out";

@Component({
  selector: "app-check-out-modal",
  templateUrl: "./check-out-modal.component.html",
  styleUrls: ["./check-out-modal.component.scss"],
})
export class CheckOutModalComponent
  extends CheckOut
  implements OnInit, OnDestroy
{
  constructor(
    private _fb: FormBuilder,
    private _ss: ShopService,
    private _as: AuthService,
    private _sps: SpinnerService,
    private _dsr: MatDialogRef<CheckOutModalComponent>,
    private _dr: MatDialog
  ) {
    super(_fb, _ss, _as, _sps, _dr, _dsr);
  }

  ngOnInit(): void {
    this.checkOutType = "MODAL";
    this.initInstance();
  }

  ngOnDestroy(): void {
    this.removeCoupon();
    this.unsubscribeSubscriptions();
  }
}
