/**
 * export enum RefreshVoucherStatus {    
    DEACTIVED = 'DEACTIVED',
    ACTIVE = 'ACTIVE',
    CALLED_OFF = 'CALLED_OFF',
    UNSTRIPED = 'UNSTRIPED',
}

 */

import { RefreshVoucherStatus } from "app/data/model/refresh-voucher/refresh-voucher-status.enum";

/**
 * Convert refresh voucher status to display voucher code string
 *
 * @param {string} refreshVocuherStatus
 * @returns {string}
 */
export function stringToRefreshVoucherStatusDisplayString(
  refreshVocuherStatus: string
): string {
  switch (refreshVocuherStatus) {
    case RefreshVoucherStatus.ACTIVE:
      return "Aktiv";

    case RefreshVoucherStatus.CALLED_OFF:
      return "Abgelaufen";

    case RefreshVoucherStatus.UNSTRIPED:
      return "Unregistriert";

    case RefreshVoucherStatus.DEACTIVED:
      return "Deaktiviert";

    default:
      return "-";
  }
}
