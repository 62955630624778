import { ShopCartMenueModalComponent } from './shop-cart-menue-modal/shop-cart-menue-modal.component';
import { OrderTableComponent } from './order-table/order-table.component';
import { from } from 'rxjs';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TreoCardModule } from '@treo/components/card';
import { SharedModule } from 'app/shared/shared.module';
import { ShopCartMenueComponent } from './shop-cart-menue/shop-cart-menue.component';
import { FormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LastOrderProductsComponent } from './last-order-products/last-order-products.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [
    ShopCartMenueComponent,
    OrderTableComponent,
    LastOrderProductsComponent,
    ShopCartMenueModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTooltipModule,
    TreoCardModule,
    SharedModule,
    RouterModule,
    OverlayModule,
    PortalModule,
    MatDialogModule,
    FormsModule,
    MatStepperModule,
    MatSelectModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
  ],
  exports: [
    ShopCartMenueComponent,
    OrderTableComponent,
    LastOrderProductsComponent
  ]
})
export class ShopItemModule { }
