import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SpinnerService } from "app/shared/spinner/spinner.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { AbstracOrder } from "app/data/model/shop/abstrac-order";
import { environment } from "environments/environment";
import { catchError, map, tap } from "rxjs/operators";
import { AuthService } from "app/core/auth/auth.service";
import { OrderProduct } from "app/data/model/shop/order-product";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private _orders: AbstracOrder[] = [];
  private _ordersSubject: BehaviorSubject<AbstracOrder[]> = new BehaviorSubject(
    []
  );

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService
  ) {}

  get orders$(): Observable<AbstracOrder[]> {
    let userId: number | null = null;
    if (!this._authService.authUserData.wp_id) {
      return of([]);
    }

    userId = this._authService.authUserData?.wp_id;
    const url =
      environment.restBaseUri +
      "wp-json/desnet-middleware/v1/order?id=" +
      userId;
    return this._httpClient.get<AbstracOrder[]>(url).pipe(
      tap((response) => {
        this._orders = response;
        this._ordersSubject.next(response);
      })
    );
  }

  get ordersListState$(): Observable<AbstracOrder[]> {
    return this._ordersSubject.asObservable();
  }

  get currentOrders(): AbstracOrder[] {
    return this._orders;
  }

  getLastOrderProducts$(): Observable<OrderProduct[]> {
    let userId: number | null = null;
    if (!this._authService.authUserData.wp_id) {
      return of([]);
    }

    userId = this._authService.authUserData?.wp_id;
    const url =
      environment.restBaseUri +
      "wp-json/desnet-middleware/v1/order/product_list?id=" +
      userId;

    return this._httpClient.get<OrderProduct[]>(url);
  }

  checkIfOrderIsCreatedByEmail$(email: string): Observable<boolean> {
    const url =
      environment.restBaseUri + "wp-json/desnet-middleware/v1/order/email/last";

    return this._httpClient.post<any>(url, { email });
  }
}
