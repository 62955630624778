<div class="xs:hidden sm:hidden">
  <div class="flex flex-col w-full bg-card shadow-md rounded overflow-hidden">
    <div class="p-6" *ngIf="showHeader">
      <div class="font-bold text-md text-secondary uppercase tracking-wider">
        Bestellungen
      </div>
    </div>
    <div class="overflow-auto" [hidden]="isLoaded">
      <table
        class="w-full bg-transparent"
        mat-table
        matSort
        [dataSource]="orderDataSource"
        [trackBy]="trackByFn"
        #recentTransactionsTable
      >
        <!-- Transaction ID -->
        <ng-container matColumnDef="id">
          <th
            class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t"
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
          >
            <span class="whitespace-no-wrap"> Bestellnummer </span>
          </th>
          <td mat-cell *matCellDef="let order">
            <span
              class="pr-6 font-medium text-sm text-secondary whitespace-no-wrap"
            >
              #{{ order.number ? order.number : order.id }}
            </span>
          </td>
        </ng-container>

        <!-- Date -->
        <ng-container matColumnDef="date_modified">
          <th
            class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t"
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
          >
            <span class="whitespace-no-wrap"> Letzte Änderung </span>
          </th>
          <td mat-cell *matCellDef="let order">
            <span class="pr-6 whitespace-no-wrap">
              {{ order.date_modified | date : "dd.MM.YYYY" }}
            </span>
          </td>
        </ng-container>

        <!-- Name -->
        <ng-container matColumnDef="invoice_url">
          <th
            class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t"
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
          >
            <span class="whitespace-no-wrap"> Rechnung </span>
          </th>
          <td mat-cell *matCellDef="let order">
            <a [href]="order.invoice_url" target="_blank"
              ><mat-icon svgIcon="request_page"></mat-icon
            ></a>
          </td>
        </ng-container>

        <!-- Amount -->
        <ng-container matColumnDef="total">
          <th
            class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t"
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
          >
            <span class="whitespace-no-wrap"> Gesamtbetrag </span>
          </th>
          <td mat-cell *matCellDef="let order">
            <span class="pr-6 font-medium whitespace-no-wrap">
              {{ order.total | currency : "EUR" }}
            </span>
          </td>
        </ng-container>

        <!-- Status -->
        <ng-container matColumnDef="status">
          <th
            class="bg-cool-gray-50 dark:bg-cool-gray-700 border-t"
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
          >
            <span class="whitespace-no-wrap"> Versandstatus </span>
          </th>
          <td mat-cell *matCellDef="let order">
            <span
              class="inline-flex items-center font-bold text-xs px-2 py-2px rounded-full tracking-wide uppercase"
              [ngClass]="{
                'red-200':
                  order.status === orderState.CANCELLED ||
                  order.status === orderState.FAILED ||
                  order.status === orderState.TRASH,
                'gray-200':
                  order.status === orderState.PENDING ||
                  order.status === orderState.REFUNDED ||
                  !order.date_paid,
                'yellow-200':
                  order.status === orderState.ONHOLD ||
                  order.status === orderState.PROCESSING,
                'green-200': order.status === orderState.COMPLETED
              }"
            >
              <span
                class="w-2 h-2 rounded-full mr-2"
                [ngClass]="{
                  'bg-red':
                    order.status === orderState.CANCELLED ||
                    order.status === orderState.FAILED ||
                    order.status === orderState.TRASH,
                  'bg-gray':
                    order.status === orderState.PENDING ||
                    order.status === orderState.REFUNDED ||
                    !order.date_paid,
                  'bg-yellow':
                    order.status === orderState.ONHOLD ||
                    order.status === orderState.PROCESSING,
                  'bg-green': order.status === orderState.COMPLETED
                }"
              ></span>
              <span class="pr-2px leading-relaxed whitespace-no-wrap">{{
                getDisplayStringForOrderStatus(order)
              }}</span>
            </span>
          </td>
        </ng-container>

        <!-- Footer -->
        <ng-container
          *ngIf="abstractDataset"
          matColumnDef="recentOrdersTableFooter"
        >
          <td
            class="px-3 border-none"
            mat-footer-cell
            *matFooterCellDef
            colspan="6"
          >
            <button
              mat-button
              [routerLink]="['/', 'apps', 'shop', 'order']"
              [color]="'primary'"
            >
              Alle Bestellungen sehen.
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="orderTableColumns"></tr>
        <tr
          class="order-row h-16"
          mat-row
          *matRowDef="let row; columns: orderTableColumns"
        ></tr>
        <div *ngIf="abstractDataset">
          <tr
            class="h-16"
            mat-footer-row
            *matFooterRowDef="['recentOrdersTableFooter']"
          ></tr>
        </div>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Keine Bestellung vorhanden</td>
        </tr>
      </table>
    </div>
    <div class="overflow-auto" *ngIf="isLoaded">
      <div class="flex w-full flex-col justify-center items-center">
        <mat-spinner></mat-spinner>
        <p>Lade Daten</p>
      </div>
    </div>
  </div>
</div>
<div class="hidden xs:flex sm:flex" *ngIf="showHeader">
  <div class="font-bold text-md text-secondary uppercase tracking-wider">
    Bestellungen
  </div>
</div>
<div class="hidden xs:flex sm:flex flex-row flex-wrap">
  <treo-card
    class="flex flex-col max-w-80 w-full p-8 pt-6 filter-list m-4"
    *ngFor="let item of data"
  >
    <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">
        Bestellung #{{ item.number ? item.number : item.id }}
      </div>
    </div>
    <div class="flex flex-col my-2">
      <div class="my-2">
        <div>Bestellnummer</div>
        <div class="text-secondary text-md">
          {{ item.number ? item.number : item.id }}
        </div>
      </div>
      <div class="my-2">
        <div>Letzte Änderung</div>
        <div class="text-secondary text-md">
          {{ (item.date_modified | date : "dd.MM.YYYY") || "---" }}
        </div>
      </div>
      <div class="my-2">
        <div>Gesamtbetrag</div>
        <div class="text-secondary text-md">
          {{ item.total | currency : "EUR" }}
        </div>
      </div>
      <div class="my-2">
        <div>Versandstatus</div>
        <span
          class="inline-flex items-center font-bold text-xs px-2 py-2px rounded-full tracking-wide uppercase"
          [ngClass]="{
            'red-200':
              item.status === orderState.CANCELLED ||
              item.status === orderState.FAILED ||
              item.status === orderState.TRASH,
            'gray-200':
              item.status === orderState.PENDING ||
              item.status === orderState.REFUNDED,
            'yellow-200':
              item.status === orderState.ONHOLD ||
              item.status === orderState.PROCESSING,
            'green-200': item.status === orderState.COMPLETED
          }"
        >
          <span
            class="w-2 h-2 rounded-full mr-2"
            [ngClass]="{
              'bg-red':
                item.status === orderState.CANCELLED ||
                item.status === orderState.FAILED ||
                item.status === orderState.TRASH,
              'bg-gray':
                item.status === orderState.PENDING ||
                item.status === orderState.REFUNDED,
              'bg-yellow':
                item.status === orderState.ONHOLD ||
                item.status === orderState.PROCESSING,
              'bg-green': item.status === orderState.COMPLETED
            }"
          ></span>
          <span class="pr-2px leading-relaxed whitespace-no-wrap">{{
            getDisplayStringForOrderStatus(item)
          }}</span>
        </span>
      </div>
      <div class="mt-8 flex flex-row justify-between">
        <a [href]="item.invoice_url" mat-button color="primary" target="_blank">
          Rechnung
        </a>
      </div>
    </div>
  </treo-card>
  <treo-card
    class="flex flex-col max-w-80 w-full p-8 pt-6 filter-list m-4"
    *ngIf="isLoaded"
  >
    <div class="flex w-full flex-col justify-center items-center">
      <mat-spinner></mat-spinner>
      <p>Lade Daten</p>
    </div>
  </treo-card>
  <treo-card
    class="flex flex-col max-w-80 w-full p-8 pt-6 filter-list m-4"
    *ngIf="!isLoaded && data.length <= 0"
  >
    <div class="flex w-full flex-col justify-center items-center">
      Es sind keine Bestellungen vorhanden
    </div>
  </treo-card>
</div>
<div
  class="hidden xs:flex sm:flex flex-row flex-wrap justify-center items-center p-3"
  *ngIf="!isLoaded && abstractDataset"
>
  <button
    mat-stroked-button
    color="primary"
    [routerLink]="['/', 'apps', 'shop', 'order']"
  >
    Alle Bestellungen sehen.
  </button>
</div>
