/**
 * 1.) Copy this file and replace the file name to -> api-key-list.ts
 * 2.) Uncomment the fill the key field out.
 */

import { ApiKeyEnum, IApiKeyConstant } from 'app/data/model/core/config/api';
import { environment } from 'environments/environment';

export class ApiKeyList {
   public static getKeyList(): IApiKeyConstant[] {
        return [
            {
                name: ApiKeyEnum.ACCOUNT.toString(),
                key: environment.accountServiceKey
            }
        ];
   }
}
