import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AbstracOrder, OrderStatus } from "app/data/model/shop/abstrac-order";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { OrderService } from "../order.service";

@Component({
  selector: "order-table",
  templateUrl: "./order-table.component.html",
  styleUrls: ["./order-table.component.scss"],
})
export class OrderTableComponent implements OnInit, AfterViewInit, OnDestroy {
  data: AbstracOrder[] = [];
  orderDataSource: MatTableDataSource<any>;
  orderTableColumns: string[];
  isLoaded = true;
  orderState = OrderStatus;

  @Input() showHeader = true;
  @Input() abstractDataset = false;

  @ViewChild("recentTransactionsTable", { read: MatSort })
  recentTransactionsTableMatSort: MatSort;

  private _subscriptionList: Subscription[] = [];

  constructor(private _orderService: OrderService) {
    this.orderDataSource = new MatTableDataSource();
    this.orderTableColumns = [
      "id",
      "date_modified",
      "invoice_url",
      "total",
      "status",
    ];
  }

  ngOnInit(): void {
    if (this.abstractDataset) {
      this._subscriptionList[this._subscriptionList.length] =
        this._orderService.orders$.pipe(take(4)).subscribe(
          (response) => {
            this.orderDataSource.data = response;
            this.data = response;
            this.isLoaded = false;
          },
          () => (this.isLoaded = false)
        );
    } else {
      this._subscriptionList[this._subscriptionList.length] =
        this._orderService.orders$.subscribe(
          (response) => {
            this.orderDataSource.data = response;
            this.data = response;
            this.isLoaded = false;
          },
          () => (this.isLoaded = false)
        );
    }
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    // Make the data source sortable
    this.orderDataSource.sort = this.recentTransactionsTableMatSort;
  }

  ngOnDestroy(): void {
    this._subscriptionList.forEach((x) => {
      if (!x.closed) {
        x.unsubscribe();
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  getDisplayStringForOrderStatus(orderItem: AbstracOrder): string {
    switch (orderItem.status) {
      case OrderStatus.CANCELLED:
        return "Gekänzelt";
      case OrderStatus.COMPLETED:
        return "Abgeschlossen";
      case OrderStatus.FAILED:
        return "Fehlerhaft";
      case OrderStatus.ONHOLD:
        return "In der Warteschleife";
      case OrderStatus.PENDING:
        return "Ausstehend";
      case OrderStatus.PROCESSING:
        return "In Bearbeitung";
      case OrderStatus.REFUNDED:
        return "Erstattet";
      case OrderStatus.TRASH:
        return "Gelöscht";

      default:
        return "Status unbekannt";
    }
  }
}
