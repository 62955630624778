import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerComponent } from './spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(private _dialog: MatDialog) { }

  public openSpinner(message: string = ''): MatDialogRef<SpinnerComponent, any> {
    return this._dialog.open(SpinnerComponent, { data: { message: message }});
  }
}
