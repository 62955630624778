<!-- Button -->
<button class="icon-button" mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="avatar">
    <img *ngIf="showAvatar && user.avatar" [src]="user.avatar" />
    <mat-icon
      *ngIf="!showAvatar || !user.avatar"
      [svgIcon]="'account_circle'"
    ></mat-icon>
    <span class="status" [ngClass]="user.status"></span>
  </span>
</button>

<mat-menu
  class="user-actions-menu"
  [xPosition]="'before'"
  #userActions="matMenu"
>
  <button mat-menu-item>
    <span class="user-info">
      <span>Signed in as</span>
      <span class="email">{{ user.email }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item [routerLink]="['/', 'pages', 'profile', 'main']">
    <mat-icon [svgIcon]="'account_circle'"></mat-icon>
    <span>Profile</span>
  </button>
  <button
    mat-menu-item
    [routerLink]="['/', 'admin', 'user-management']"
    *ngIf="hasAdminOrMerchantOrSuperAdminRole"
  >
    <mat-icon [svgIcon]="'supervised_user_circle'"></mat-icon>
    <span>Benutzerverwaltung</span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
    <span>Ausloggen</span>
  </button>
</mat-menu>
