import { Injectable } from '@angular/core';
import { HmacSHA1 } from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WoocomerceService {
  nonce = '';
  currentTimestamp = 0;
  customerKey = environment.consumeKey; 
  customerSecret = environment.secretKey;  
  constructor() { }

  authenticateApi(method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'Patch', url: string, params: any): string {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    this.nonce = '';
    for (let i = 0; i < 6; i++) {
        this.nonce += possible.charAt(Math.floor(Math.random() * possible.length));
    }    
    this.currentTimestamp = Math.floor(new Date().getTime() / 1000);

    const authParam = {
        oauth_consumer_key : this.customerKey,
        oauth_nonce : this.nonce,
        oauth_signature_method : 'HMAC-SHA1',
        oauth_timestamp : this.currentTimestamp,
        oauth_version : '1.0',
    };
    const parameters = Object.assign({}, authParam, params);
    let signatureStr = '';
    Object.keys(parameters).sort().forEach((key) => {
        if (signatureStr === '') {
            signatureStr += key + '=' + parameters[key];
        }
        else {
            signatureStr += '&' + key + '=' + parameters[key];
        }
    });
    let paramStr = '';
    Object.keys(params).sort().forEach((key) => {

        paramStr += '&' + key + '=' + parameters[key];
    });
    return url + 
      '?oauth_consumer_key=' + this.customerKey + '&oauth_nonce=' + this.nonce + '&oauth_signature_method=HMAC-SHA1&oauth_timestamp=' + 
      this.currentTimestamp + '&oauth_version=1.0&oauth_signature=' + 
      Base64.stringify(HmacSHA1(method + '&' + encodeURIComponent(url) + '&' + encodeURIComponent(signatureStr), this.customerSecret + '&')) + paramStr;

  }
}
