import { Pipe, PipeTransform } from "@angular/core";
import { stringToRefreshVoucherStatusDisplayString } from "../util/string-refresh-voucher-status.util";

@Pipe({
  name: "refreshVocherStatus",
})
export class RefreshVocherStatusPipe implements PipeTransform {
  transform(value: string): string {
    return stringToRefreshVoucherStatusDisplayString(value);
  }
}
