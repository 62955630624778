<h2 mat-dialog-title class="bg-green-500">Erfolgreich</h2>
<mat-dialog-content class="mat-typography">
    <div class="flex flex-row jsutify-center items-center h-full w-full successfull-message">
        <mat-icon svgIcon="check_circle"></mat-icon>
        <p>{{ data.message }}</p>
    </div>    
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Schließen</button>
</mat-dialog-actions>

