import { AuthService } from "app/core/auth/auth.service";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { User } from "app/layout/common/user/user.types";
import { UserService } from "app/layout/common/user/user.service";
import { UserRole } from "app/data/model/auth/auth-response";

@Component({
  selector: "user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "user",
})
export class UserComponent implements OnInit, OnDestroy {
  @Input()
  showAvatar: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;
  private _user: User;

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {Router} _router
   * @param {UserService} _userService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _authService: AuthService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.showAvatar = true;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  @Input()
  set user(value: User) {
    // Save the user
    this._user = value;

    // Store the user in the service
    this._userService.user = value;
  }

  get user(): User {
    return this._user;
  }

  get hasAdminOrMerchantOrSuperAdminRole(): boolean {
    return this._authService.userHasRole([
      UserRole.ADMIN,
      UserRole.MERCHANT,
      UserRole.SUPERADMIN,
    ]);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        this._user = user;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status): void {
    // Update the user data
    this.user.status = status;

    // Update the user on the server
    this._userService.update(this.user);
  }

  /**
   * Sign out
   */
  signOut(): void {
    this._router.navigate(["/sign-out"]);
  }
}
