<div class="flex flex-col w-full bg-card shadow-md rounded overflow-hidden">
  <div class="p-6">
    <div class="font-bold text-md text-secondary uppercase tracking-wider">
      Zuletzt bestellte Produkte
    </div>
  </div>
  <div class="overflow-auto" [hidden]="isLoaded">
    <div class="flex flex-row flex-auto flex-wrap">
      <treo-card
        class="flex flex-col justify-between max-w-80 w-full m-2 filter-shopping"
        [ngClass]="{ 'opacity-75': isProductSoldOut(productItem) }"
        *ngFor="let productItem of productList"
      >
        <div class="relative flex justify-center items-center xs:p-2 sm:p-2">
          <img
            class="object-cover hidden product-pic xs:flex sm:flex"
            [ngClass]="{ 'opacity-75': isProductSoldOut(productItem) }"
            [src]="getProductImage(productItem)"
          />
          <img
            class="object-cover xs:hidden sm:hidden"
            [ngClass]="{ 'opacity-75': isProductSoldOut(productItem) }"
            [src]="getProductImage(productItem)"
          />
        </div>
        <div
          class="mx-8 mt-1 mb-0"
          [ngClass]="{ 'opacity-75': isProductSoldOut(productItem) }"
        >
          <div class="flex flex-col">
            <div class="text-2xl leading-tight">{{ productItem.name }}</div>
            <div class="text-3xl font-semibold mt-2">
              {{ (productItem.price | currency : "EUR") || "€0.00" }}
            </div>
          </div>
          <div class="mt-1 -mx-8">
            <button
              class="w-full rounded-t-none"
              mat-flat-button
              *ngIf="!isProductSoldOut(productItem)"
              (click)="addProductToCart(productItem)"
              [color]="'primary'"
            >
              Zum Warenkorb hinzufügen
            </button>
            <button
              class="w-full rounded-t-none"
              mat-flat-button
              disabled
              *ngIf="isProductSoldOut(productItem)"
            >
              Ausverkauft
            </button>
          </div>
        </div>
      </treo-card>
    </div>
  </div>
  <div class="overflow-auto" *ngIf="!isLoaded && productList.length <= 0">
    <div class="flex w-full flex-col justify-center items-center my-5 px-5">
      Keine Bestellung vorhanden
    </div>
  </div>
  <div class="overflow-auto" *ngIf="isLoaded">
    <div class="flex w-full flex-col justify-center items-center">
      <mat-spinner></mat-spinner>
      <p>Lade Daten</p>
    </div>
  </div>
</div>
