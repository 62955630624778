<h2 mat-dialog-title class="bg-red-500 p-3">Fehler</h2>
<mat-dialog-content class="mat-typography">
    <div class="flex flex-row jsutify-center items-center h-full w-full error-message">
        <mat-icon class="m-3" svgIcon="error"></mat-icon>
        <p class="m-3" *ngIf="data.code">
          Code: {{ data.code }} 
          <br/>
          Meldung: {{ data.message }}
        </p>
        <p *ngIf="!data.code">
          Meldung: {{ data.message }}
        </p>
    </div>    
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Schließen</button>
</mat-dialog-actions>