import { Environment } from "app/data/model/app/env.model";

export const environment: Environment = {
  production: true,
  restBaseUri: "",
  env: "PROD-DEBUG",
  accountServiceUri: "https://kw-account-dev.desnet.de/",
  accountServiceKey:
    "H0HXSyYT1781Rn3A0mEM8ByF4T2v4U1A5ytPKqrV4T5HVihsaSJmMe9Gtk91xPcZVc7vU7jA4dV91msc2mW6zAJM3ItMZEp1LmTg8AAKl2yCzsjIaZlLvJGJyNiCNX46",
  enableThemeSetting: false,
  consumeKey: "",
  secretKey: "",
  userLoginForm: {
    user: "",
    password: "",
  },
};
