import { HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, PreloadAllModules, RouterModule } from "@angular/router";
import { MarkdownModule } from "ngx-markdown";
import { TreoModule } from "@treo";
import { TreoConfigModule } from "@treo/services/config";
import { TreoMockApiModule } from "@treo/lib/mock-api";
import { CoreModule } from "app/core/core.module";
import { appConfig } from "app/core/config/app.config";
import { mockDataServices } from "app/data/mock";
import { LayoutModule } from "app/layout/layout.module";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import { MatDialogModule } from "@angular/material/dialog";
import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
registerLocaleData(localeDe);

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  preloadingStrategy: PreloadAllModules,
  relativeLinkResolution: "legacy",
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Treo & Treo Mock API
    TreoModule,
    TreoConfigModule.forRoot(appConfig),
    TreoMockApiModule.forRoot(mockDataServices),

    // Core
    CoreModule,

    // Layout
    LayoutModule,

    // 3rd party modules
    MarkdownModule.forRoot({}),

    // For Init Service
    HttpClientModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: "de-DE" + "-u-tz-CET" }],
  bootstrap: [AppComponent],
})
export class AppModule {}
