import { Injectable } from '@angular/core';
import { ApiKeyEnum, IApiKeyConstant } from 'app/data/model/core/config/api';
import { ApiKeyList } from 'app/keys/api-key-list';


@Injectable({
  providedIn: 'root'
})
export class ApiKeyService { 

  private _apiKeyList: IApiKeyConstant[] = ApiKeyList.getKeyList();

  /**
   * Returns the api key by name.
   * 
   * @param {ApiKeyEnum} name 
   * @returns {string | undefined}
   */
  getApiKeyByName(name: ApiKeyEnum): string | undefined {
    return this._apiKeyList.find(x => x.name === name.toString())?.key;
  }
}
