import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from 'environments/environment';
import { HostNeedApiKey, hostNeedApiKeyList } from 'app/constants/host-need-api-key-list';
import { ApiKeyService } from '../config/api-key.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     *
     * @param {AuthService} _authService
     */
    constructor(
        private _authService: AuthService,
        private _apiKeyService: ApiKeyService
    )
    {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Clone the request object
        let newReq = req.clone();
        const headers: {[name: string]: string} = {};

        const needApiKey: HostNeedApiKey = hostNeedApiKeyList.find(x => {
            return newReq.url.includes(x.host);
        });
        
        if (needApiKey !== undefined && needApiKey !== null) {
            const apiKey: string = this._apiKeyService.getApiKeyByName(needApiKey.nameOfApiKey);

            if (apiKey !== undefined && apiKey !== null) {
                headers['api-authorization'] = apiKey;
            }
        }

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.

        if (typeof this._authService.accessToken === 'string') {
            if (this._authService.accessToken === 'null' || this._authService.accessToken === 'undefined') {
                this._authService.resetInstance();
            }
        }
                
        if (newReq.url.includes(environment.accountServiceUri) && (this._authService.accessToken !== null && this._authService.accessToken !== undefined)) {
            if (!AuthUtils.isTokenExpired(this._authService.accessToken)) {                
                headers['Authorization'] = 'Bearer ' + this._authService.accessToken;
            } 
        }

        if (Object.keys(headers).length > 0) {
            const httpHeaders = new HttpHeaders(headers);
            newReq = req.clone({ headers: httpHeaders });
        }
        
        return next.handle(newReq);
    }
}
