<h2 mat-dialog-title class="p-3">{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
    <div class="flex xs:flex-col md:flex-row justify-center items-center h-full w-full error-message">
        <mat-icon class="m-3" svgIcon="help"></mat-icon>
        <p class="m-3" [innerHTML]="data.message"></p>
    </div>    
</mat-dialog-content>
<mat-dialog-actions class="flex flex-row justify-end items-end space-x-3">
  <button mat-button mat-dialog-close>Nein</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ja</button>
</mat-dialog-actions>