import { ShopService } from "./../../modules/shop/shop.service";
import { User } from "./../../layout/common/user/user.types";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError, forkJoin } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { AuthUtils } from "app/core/auth/auth.utils";
import {
  AuthResponse,
  AuthUserData,
  BillingAddress,
  BillingAddressRequest,
  ShippingAddress,
  ShippingAddressRequest,
  UserRole,
} from "app/data/model/auth/auth-response";
import { environment } from "environments/environment";
import { UserService } from "app/layout/common/user/user.service";
import { PasswordResetRequest } from "app/data/model/auth/password-reset-request";

interface AuthUserProfileUpdateRequest {
  first_name: string;
  last_name: string;
  active?: boolean;
}

@Injectable()
export class AuthService {
  // Private
  private _authenticated: boolean;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _shopService: ShopService
  ) {
    // Set the defaults
    this._authenticated = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem("access_token", token);
  }

  get accessToken(): string {
    return localStorage.getItem("access_token");
  }

  /**
   * Setter & getter for access token
   */
  set sessionData(token: string) {
    localStorage.setItem("session_data", token);
  }

  get sessionData(): string | null {
    return localStorage.getItem("session_data");
  }

  get authUserData(): AuthUserData | undefined {
    const userProfile: AuthResponse | null =
      this.sessionData !== null ? JSON.parse(atob(this.sessionData)) : null;
    if (!userProfile) {
      return undefined;
    }

    const encryptedUserProfileString: string = atob(atob(userProfile.data));
    return JSON.parse(encryptedUserProfileString);
  }

  get userMap(): User | undefined {
    const authUserData: AuthUserData | undefined = this.authUserData;
    if (!authUserData) {
      return undefined;
    }

    const userProfile: User = {
      email: authUserData.email,
      id: authUserData.uuid,
      name: authUserData.first_name + " " + authUserData.last_name,
    };

    return userProfile;
  }

  get authModel(): AuthResponse | undefined {
    const userProfile: AuthResponse | null =
      this.sessionData !== null ? JSON.parse(atob(this.sessionData)) : null;
    if (!userProfile) {
      return undefined;
    }

    return userProfile;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError("User is already logged in.");
    }

    return this._httpClient
      .post(environment.accountServiceUri + "api/login", credentials)
      .pipe(
        tap((response: AuthResponse) => {
          const userData: AuthResponse = response;

          // Store session
          this.sessionData = btoa(JSON.stringify(userData));

          // Store the access token in the local storage
          this.accessToken = userData.token;

          // Set user profile
          this._userService.user = this.userMap;

          // Set the authenticated flag to true
          this._authenticated = true;
        })
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .get(environment.accountServiceUri + "api/refresh")
      .pipe(
        catchError(() => {
          // Return false
          return of(false);
        }),
        switchMap((response: AuthResponse) => {
          const userData: AuthResponse = response;

          // Store session
          this.sessionData = btoa(JSON.stringify(userData));

          // Store the access token in the local storage
          this.accessToken = userData.token;

          // Set user profile
          this._userService.user = this.userMap;

          // Set the authenticated flag to true
          this._authenticated = true;

          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Reset the current instance
    this.resetInstance();

    // Return the observable
    return this._httpClient.get(environment.accountServiceUri + "api/logout");
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  updateCurrentUserPassword(newPassword: string): Promise<any> {
    const encryptedPassword = btoa(btoa(newPassword));
    return this._httpClient
      .post(environment.accountServiceUri + "api/user/password", {
        psw: encryptedPassword,
      })
      .toPromise();
  }

  updateUserMetaData(userMetaData: AuthUserData): void {
    if (userMetaData !== null && userMetaData !== undefined) {
      return;
    }

    const sessionObject: AuthResponse | undefined = JSON.parse(
      atob(this.sessionData)
    );
    if (!sessionObject) {
      return;
    }

    sessionObject.data = btoa(btoa(JSON.stringify(userMetaData)));
    this.sessionData = btoa(JSON.stringify(sessionObject));
  }

  sendUpdatedUserBillingAddress(
    billingAddress: BillingAddress,
    uuid: string | null = null
  ): Promise<any> {
    const requestBody: BillingAddressRequest = {
      user_id: uuid !== null ? uuid : this.authUserData.uuid,
      ...billingAddress,
    };

    return this._httpClient
      .put(
        environment.accountServiceUri + `api/user/billing/${billingAddress.id}`,
        requestBody
      )
      .toPromise();
  }

  sendUpdatedUserShippingAddress(
    shippingAddress: ShippingAddress,
    uuid: string | null = null
  ): Promise<any> {
    const requestBody: ShippingAddressRequest = {
      user_id: uuid !== null ? uuid : this.authUserData.uuid,
      ...shippingAddress,
    };

    return this._httpClient
      .put(
        environment.accountServiceUri +
          `api/user/shipping/${shippingAddress.id}`,
        requestBody
      )
      .toPromise();
  }

  sendUpdatedUserProfile(userProfileData: AuthUserData): Promise<any> {
    const requestBody: AuthUserProfileUpdateRequest = {
      first_name: userProfileData.first_name,
      last_name: userProfileData.last_name,
    };

    return this._httpClient
      .put(
        environment.accountServiceUri +
          `api/user/profile/${userProfileData.uuid}`,
        requestBody
      )
      .toPromise();
  }

  refreshUserProfile(): Promise<any> {
    return this._httpClient
      .get<{ data: string }>(environment.accountServiceUri + "api/user/profile")
      .pipe(
        tap((response) => {
          const authLoginResponse: AuthResponse | null =
            this.sessionData !== null
              ? JSON.parse(atob(this.sessionData))
              : null;
          if (!authLoginResponse) {
            return;
          }

          authLoginResponse.data = response.data;
          this.sessionData = btoa(JSON.stringify(authLoginResponse));
        })
      )
      .toPromise();
  }

  userHasRole(roles: UserRole[]): boolean {
    return roles.includes(this.authUserData.role);
  }

  resetInstance(): void {
    // Remove the access token from the local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("session_data");
    this._shopService.clearAllInstanceData();

    // Set the authenticated flag to false
    this._authenticated = false;
  }

  /**
   * Send password reeset request
   *
   * @param {string} email
   * @returns {Promise<any>}
   */
  sendPasswordResetRequest(email: string): Promise<any> {
    return this._httpClient
      .post(environment.accountServiceUri + "api/send-password-reset", {
        email,
      })
      .toPromise();
  }

  /**
   * Send password reeset request
   *
   * @param {string} email
   * @returns {Promise<any>}
   */
  checkPasswordResetToken(email: string, token: string): Promise<any> {
    const requestBody = {
      email: email,
      token: token,
    };

    return this._httpClient
      .post(
        environment.accountServiceUri + "api/password-reset-validate",
        requestBody
      )
      .toPromise();
  }

  /**
   * Send password reeset request
   *
   * @param {PasswordResetRequest} requestBody
   * @returns {Promise<any>}
   */
  sendPasswordResetChangeRequest(
    requestBody: PasswordResetRequest
  ): Promise<any> {
    return this._httpClient
      .post(environment.accountServiceUri + "api/password-reset", requestBody)
      .toPromise();
  }

  /**
   * Temp Function
   */
  updateWPUserId(wpId: number): Promise<any> {
    const userUUId: string = this.authUserData.uuid;
    return this._httpClient
      .put(
        environment.accountServiceUri +
          `api/user/${userUUId}/update/wp/${wpId}`,
        {}
      )
      .toPromise();
  }
}
