<!-- Navigation -->
<treo-vertical-navigation
  class="theme-dark"
  [appearance]="'classic'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="data.navigation.default"
  [opened]="!isScreenSmall"
>
  <div treoVerticalNavigationContentHeader>
    <!-- Actions -->
    <div class="actions">
      <!-- Logo -->
      <div class="logo">
        <img src="assets/images/logo/logo-light.png" />
      </div>

      <!-- Spacer -->
      <div class="spacer"></div>

      <!-- User -->
      <user
        [user]="userProfile"
        *ngIf="userProfile !== undefined"
        [showAvatar]="false"
      ></user>
    </div>

    <!-- User -->
    <div class="user">
      <div class="avatar">
        <!-- <img *ngIf="data.user.avatar"
                     [src]="data.user.avatar"> -->
        <mat-icon [svgIcon]="'account_circle'"></mat-icon>
      </div>
      <div class="info">
        <div class="name">
          {{ userProfile !== undefined ? userProfile.name : "" }}
        </div>
        <div class="email text-secondary">
          {{ userProfile !== undefined ? userProfile.email : "" }}
        </div>
      </div>
    </div>
  </div>
</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">
  <!-- Header -->
  <div class="header">
    <!-- Navigation toggle button -->
    <button
      class="navigation-toggle-button"
      mat-icon-button
      (click)="toggleNavigation('mainNavigation')"
    >
      <mat-icon [svgIcon]="'menu'"></mat-icon>
    </button>

    <!-- Spacer -->
    <div class="spacer"></div>

    <!-- Cart menue -->
    <shop-cart-menue
      *ngIf="appConfigCollection[appConfigEnum.ENABLE_ONLINE_SHOP]"
    ></shop-cart-menue>
  </div>

  <!-- Content -->
  <div class="content">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
