import { ApiKeyEnum } from 'app/data/model/core/config/api';
import { environment } from 'environments/environment';

export interface HostNeedApiKey {
    host: string;
    nameOfApiKey: ApiKeyEnum;
}

export const hostNeedApiKeyList: HostNeedApiKey[] = [
    { host: environment.accountServiceUri, nameOfApiKey: ApiKeyEnum.ACCOUNT }
];
