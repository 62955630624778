import { Pipe, PipeTransform } from "@angular/core";
import { stringToVocuherPackageType } from "../util/string-package.util";

@Pipe({
  name: "packageName",
})
export class PackageNamePipe implements PipeTransform {
  transform(value: any | null): unknown {
    if (value === null) {
      return "-";
    }

    return stringToVocuherPackageType(value);
  }
}
