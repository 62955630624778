import { TreoNavigationItem } from "@treo/components/navigation";
import { UserRole } from "app/data/model/auth/auth-response";
import { environment } from "environments/environment";
import { cloneDeep } from "lodash";
import { KwAppConfig } from "../config/models/app-config.models";

export class FilterHelper {
  public static getNavigationItmeByUserRole(
    navigationList: TreoNavigationItem[],
    userRole: UserRole,
    appConfig: KwAppConfig
  ): TreoNavigationItem[] {
    const returnItemList: TreoNavigationItem[] = [];

    navigationList.forEach((navigationItem: TreoNavigationItem) => {
      let returnChildren = [];
      if (navigationItem.children?.length > 0) {
        returnChildren = FilterHelper.getNavigationItmeByUserRole(
          navigationItem.children,
          userRole,
          appConfig
        );
      }

      if (returnChildren.length > 0) {
        const cloneNavigationItem = cloneDeep(navigationItem);
        cloneNavigationItem.children = returnChildren;
        returnItemList.push(cloneNavigationItem);
        return;
      }

      const env: string[] = navigationItem.meta?.env || null;
      if (env !== null) {
        if (!env.includes(environment.env)) {
          return;
        }
      }

      const allowUserRoleList: string[] = navigationItem.meta?.roles || [];
      if (
        allowUserRoleList.length > 0 &&
        allowUserRoleList.includes(userRole)
      ) {
        returnItemList.push(navigationItem);
      }
    });

    return returnItemList;
  }
}
