import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fail-message',
  templateUrl: './fail-message.component.html',
  styleUrls: ['./fail-message.component.scss']
})
export class FailMessageComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string, code?: number }) { }

}
