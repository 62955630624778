import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from "@angular/material/dialog";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SpinnerComponent } from "./spinner/spinner.component";
import { SuccessMessageComponent } from "./success-message/success-message.component";
import { FailMessageComponent } from "./fail-message/fail-message.component";
import { ConfirmMessageComponent } from "./confirm-message/confirm-message.component";
import { MatButtonModule } from "@angular/material/button";
import { MomentPipe } from "./pipe/moment.pipe";
import { VoucherCodePipe } from "./pipe/voucher-code.pipe";
import { PersonNameObjectPipe } from "./pipe/person-name-object.pipe";
import { RefreshVocherStatusPipe } from "./pipe/refresh-vocher-status.pipe";
import { PackageNamePipe } from "./pipe/package-name.pipe";

@NgModule({
  imports: [
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerComponent,
    MomentPipe,
    VoucherCodePipe,
    PersonNameObjectPipe,
    RefreshVocherStatusPipe,
    PackageNamePipe,
  ],
  declarations: [
    SpinnerComponent,
    SuccessMessageComponent,
    FailMessageComponent,
    ConfirmMessageComponent,
    MomentPipe,
    VoucherCodePipe,
    PersonNameObjectPipe,
    RefreshVocherStatusPipe,
    PackageNamePipe,
  ],
})
export class SharedModule {}
