export interface KwAppConfig {
  [k: string]: string;
}

export enum KwAppConfigKey {
  MAINTANCE = "MAINTANCE",
  GENERATE_GIFT_VOUCHER_PDF_DOCUMENT = "GENERATE_GIFT_VOUCHER_PDF_DOCUMENT",
  SHOW_STATISTIC_IN_UI = "SHOW_STATISTIC_IN_UI",
  ENABLE_ONLINE_SHOP = "ENABLE_ONLINE_SHOP",
  ONLINE_SHOP_URL = "ONLINE_SHOP_URL",
}
