import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AppConfigService } from "./core/config/app-config.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  private _htmlAddClassListener: HTMLHtmlElement;

  constructor(
    private _configService: AppConfigService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    /**
     * Fixed css scroll blockade on touch event, when 'cdk-global-scrollblock' is in css class
     * attribute setting.
     */
    this._htmlAddClassListener = document.querySelector("html");
    this._htmlAddClassListener.addEventListener("touchstart", () => {
      this._htmlAddClassListener.removeAttribute("class");
    });

    this._configService.loadAppConfigFromBackend$().subscribe();

    /**
     * Fix chrome crash bug by select an mat-select
     */
    this._document.addEventListener("DOMNodeInserted", () => {
      const elements = document.querySelectorAll("[aria-owns]");

      elements.forEach((element) => {
        element.removeAttribute("aria-owns");
      });
    });
  }

  ngOnDestroy(): void {
    if (this._htmlAddClassListener) {
      this._htmlAddClassListener.removeEventListener("touchstart", () => {});
    }

    this._document.removeEventListener("DOMNodeInserted", () => {});
  }
}
