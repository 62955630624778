import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "personNameObject",
})
export class PersonNameObjectPipe implements PipeTransform {
  transform(value: string): string {
    try {
      if (value === null) {
        return "-";
      }

      const nameObject: { firstName: string; lastName: string } =
        JSON.parse(value);

      return nameObject.firstName + " " + nameObject.lastName;
    } catch (error) {
      return "-";
    }
  }
}
