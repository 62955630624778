import { cloneDeep } from "lodash";

/**
 * Convert voucher code to display voucher code string
 *
 * @param {string} code
 * @param {string} prefix
 * @param {number} steps
 * @param {number} lengthOfSubString
 * @returns {string}
 */
export function stringToVoucherCode(
  code: string,
  prefix = "",
  steps = 4,
  lengthOfSubString = 4
): string {
  const orginalString = cloneDeep(code);
  let newString = prefix;
  let currentStringIndex = 0;

  for (let index = 0; index < steps; index++) {
    const subCodeString = orginalString.substring(
      currentStringIndex,
      currentStringIndex + lengthOfSubString
    );
    currentStringIndex = currentStringIndex + lengthOfSubString;

    if (index === steps - 1) {
      newString += subCodeString.toUpperCase();
    } else {
      newString += subCodeString.toUpperCase() + "-";
    }
  }

  return newString;
}
