import { ShopService } from './../shop.service';
import { WoocommerceProduct } from 'app/data/model/shop/woocommerce-product';
import { OrderService } from 'app/modules/shop/order.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderProduct } from 'app/data/model/shop/order-product';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'last-order-products',
  templateUrl: './last-order-products.component.html',
  styleUrls: ['./last-order-products.component.scss']
})
export class LastOrderProductsComponent implements OnInit, OnDestroy {

  isLoaded = true;
  productList: WoocommerceProduct[] = [];

  private _subscriptionList: Subscription[] = [];

  constructor(private _orderService: OrderService, private _shopService: ShopService) { }

  ngOnInit(): void {
    this._subscriptionList[this._subscriptionList.length] = this._orderService
      .getLastOrderProducts$()
      .subscribe(
        orderProductList => this.prepareLastProductOrderList(orderProductList),
        () => this.isLoaded = false
      );
  }

  ngOnDestroy(): void {
    this._subscriptionList.forEach(x => {
      if (!x.closed) {
        x.unsubscribe();
      }
    });
  }

  private prepareLastProductOrderList(productOrderList: OrderProduct[]): void {
    if (productOrderList.length <= 0) {
      this.isLoaded = false;
      return;
    }

    const sortedOrderListByDate: OrderProduct[] = productOrderList.sort((a, b) => {
      const orderADate: string = moment(a.date_created).format('YYYYMMDD');
      const orderBDate: string = moment(b.date_created).format('YYYYMMDD');

      if (orderADate < orderBDate) {
        return 1;
      } else {
        return -1;
      }
    });

    const productList: WoocommerceProduct[] = [];

    for (const orderItem of sortedOrderListByDate.slice(0, sortedOrderListByDate.length >= 5 ? 5 : sortedOrderListByDate.length)) {

      for (const orderProductItem of orderItem.productList) {
        if (orderProductItem === undefined || orderProductItem === null) {
          continue;
        }

        if (!productList.find(x => x.id === orderProductItem.id)) {
          const cloneOrderProductItem = cloneDeep(orderProductItem);
          cloneOrderProductItem.amount = this._shopService.getMinAmountValue(cloneOrderProductItem);
          productList.push(cloneOrderProductItem);
        }
      }
    }

    this.productList = productList.slice(0, productList.length >= 5 ? 5 : productList.length);
    this.isLoaded = false;  
  }

  public getProductImage(product: WoocommerceProduct): string {
    if (!product.images) {
      return 'assets/images/cards/sneakers-03-448x560.jpg';
    }

    if (product.images.length <= 0) {
      return 'assets/images/cards/sneakers-03-448x560.jpg';
    }

    return product.images[0].src;
  }

  public addProductToCart(productItem: WoocommerceProduct): void {
    this._shopService.addProductItem(productItem);
  }

  isProductSoldOut(productItem: WoocommerceProduct): boolean {
    return productItem.stock_quantity <= 0;
  }

}
