import { RefreshVoucherPackageType } from "app/data/model/refresh-voucher/refresh-voucher";
import { PackageType } from "app/data/model/voucher/voucher";

/**
 * Convert voucher kind to display voucher kind string
 *
 * @param {VoucherType | RefreshVoucherPackageType} kind
 * @returns {string}
 */
export function stringToVocuherPackageType(
  kind: PackageType | RefreshVoucherPackageType
): string {
  switch (kind) {
    case "LIGHT BLEACHING PAKET":
    case "LIGHT":
      return "Light";

    case "MASTER BLEACHING PAKET":
    case "MASTER":
      return "Master";

    case "PREMIUM BLEACHING PAKET":
    case "PREMIUM":
      return "Premium";

    case "DIAMOND BLEACHING PAKET":
    case "DIAMOND":
      return "Diamond";

    default:
      return "-";
  }
}
