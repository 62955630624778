/* tslint:disable:max-line-length */
import { TreoNavigationItem } from "@treo/components/navigation";
import { KwAppConfigKey } from "app/core/config/models/app-config.models";
import { UserRole } from "app/data/model/auth/auth-response";

export const defaultNavigation: TreoNavigationItem[] = [
  {
    id: "dashboards",
    title: "Dashboard",
    type: "group",
    icon: "heroicons_outline:home",
    meta: {
      roles: [UserRole.ADMIN, UserRole.MERCHANT],
      appConfig: {
        [KwAppConfigKey.ENABLE_ONLINE_SHOP]: true,
      },
    },
    children: [
      {
        id: "dashboards.finance",
        title: "Übersicht",
        type: "basic",
        icon: "heroicons_outline:chart-square-bar",
        link: "/dashboards/finance",
      },
    ],
  },
  {
    id: "daily-busines",
    title: "Gutscheine",
    subtitle: "Einlösen und Administrieren",
    type: "group",
    icon: "heroicons_outline:home",
    meta: {
      roles: [UserRole.MERCHANT, UserRole.EMPLOYEE],
      env: ["PROD", "DEV", "PROD-DEBUG", "LOCAL"],
    },
    children: [
      {
        id: "daily-busines.voucher",
        title: "Gutschein einlösen",
        type: "basic",
        icon: "heroicons_outline:check-circle",
        link: "/apps/voucher/main",
        meta: {
          roles: [UserRole.MERCHANT, UserRole.EMPLOYEE],
        },
      },
      {
        id: "daily-busines.voucher-historie",
        title: "Gutschein Historie",
        type: "basic",
        icon: "heroicons_outline:check-circle",
        link: "/apps/voucher/historie",
        meta: {
          roles: [UserRole.MERCHANT],
        },
      },
    ],
  },
  {
    id: "daily-busines",
    title: "Gutscheine",
    subtitle: "Einlösen und Administrieren",
    type: "group",
    icon: "heroicons_outline:home",
    meta: {
      roles: [UserRole.ADMIN],
      env: ["PROD", "DEV", "PROD-DEBUG", "LOCAL"],
    },
    children: [
      {
        id: "daily-busines.voucher",
        title: "Gutscheine einlösen",
        type: "basic",
        icon: "heroicons_outline:check-circle",
        link: "/apps/voucher/main",
      },
      {
        id: "daily-busines.voucher-grid",
        title: "Gutscheine administrieren",
        type: "basic",
        icon: "heroicons_outline:check-circle",
        link: "/apps/voucher/admin",
      },
    ],
  },
  {
    id: "refresh-voucher",
    title: "Refresh Gutscheine",
    subtitle: "Einlösen und Administrieren",
    type: "group",
    icon: "heroicons_outline:home",
    meta: {
      roles: [UserRole.ADMIN, UserRole.EMPLOYEE, UserRole.MERCHANT],
      env: ["DEV", "PROD-DEBUG", "LOCAL"],
    },
    children: [
      {
        id: "refresh-voucher.main",
        title: "Refresh Karte",
        type: "basic",
        icon: "heroicons_outline:check-circle",
        link: "/apps/refresh-voucher/main",
      },
    ],
  },
  {
    id: "inventar",
    title: "Inventarisierung",
    subtitle: "Warenbestand",
    type: "group",
    icon: "heroicons_outline:home",
    meta: {
      roles: [UserRole.MERCHANT, UserRole.ADMIN],
    },
    children: [
      {
        id: "inventar.ecommerce-out",
        title: "Shop",
        type: "basic",
        icon: "heroicons_outline:shopping-cart",
        externalLink: true,
        link: "https://merchant-shop.konzept-weiss.de/",
        meta: {
          roles: [UserRole.ADMIN, UserRole.MERCHANT],
        },
      },
      {
        id: "inventar.invoice",
        title: "Bestellungen (Alter Shop)",
        type: "basic",
        icon: "heroicons_outline:receipt-tax",
        link: "/apps/shop/order",
        meta: {
          roles: [UserRole.MERCHANT],
        },
      },
    ],
  },
  {
    id: "file",
    title: "Cloud",
    type: "group",
    icon: "heroicons_outline:home",
    meta: {
      roles: [UserRole.ADMIN, UserRole.MERCHANT],
      env: ["DEV", "PROD-DEBUG", "LOCAL"],
    },
    children: [
      {
        id: "file.document-center",
        title: "Download-Bereich",
        type: "basic",
        icon: "heroicons_outline:cloud-download",
        link: "/doc/center",
      },
    ],
  },
  {
    id: "admin",
    title: "Administration",
    subtitle: "Einstellungen",
    type: "group",
    icon: "heroicons_outline:home",
    children: [
      {
        id: "admin.useraccount",
        title: "Benutzerverwaltung",
        type: "basic",
        icon: "heroicons_outline:users",
        link: "/admin/user-management",
        meta: {
          roles: [UserRole.ADMIN, UserRole.MERCHANT, UserRole.SUPERADMIN],
        },
      },
      {
        id: "admin.stores",
        title: "Shops",
        type: "basic",
        icon: "heroicons_outline:office-building",
        link: "/master-data/store",
        meta: {
          roles: [UserRole.ADMIN],
        },
      },
    ],
  },
];
export const compactNavigation: TreoNavigationItem[] = [
  {
    id: "dashboards",
    title: "Dashboards",
    type: "aside",
    icon: "heroicons_outline:home",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "applications",
    title: "Apps",
    type: "aside",
    icon: "heroicons_outline:qrcode",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "pages",
    title: "Pages",
    type: "aside",
    icon: "heroicons_outline:document-duplicate",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "user-interface",
    title: "UI",
    type: "aside",
    icon: "heroicons_outline:collection",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "navigation-features",
    title: "Navigation",
    type: "aside",
    icon: "heroicons_outline:menu",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const futuristicNavigation: TreoNavigationItem[] = [
  {
    id: "apps",
    title: "APPS",
    type: "group",
    children: [
      {
        id: "applications.dashboards.finance",
        title: "Finance",
        type: "basic",
        icon: "heroicons_outline:cash",
        link: "/dashboards/finance",
      },
      {
        id: "applications.dashboards.analytics",
        title: "Analytics",
        type: "basic",
        icon: "heroicons_outline:chart-pie",
        link: "/dashboards/analytics",
      },
      {
        id: "applications.dashboards.crypto",
        title: "Crypto",
        type: "basic",
        icon: "heroicons_outline:currency-dollar",
        link: "/dashboards/crypto",
      },
      {
        id: "applications.calendar",
        title: "Calendar",
        type: "basic",
        icon: "heroicons_outline:calendar",
        link: "/apps/calendar",
      },
      {
        id: "applications.contacts",
        title: "Contacts",
        type: "basic",
        icon: "heroicons_outline:user-group",
        link: "/apps/contacts",
      },
      {
        id: "applications.ecommerce",
        title: "ECommerce",
        type: "collapsable",
        icon: "heroicons_outline:shopping-cart",
        children: [
          {
            id: "applications.ecommerce.inventory",
            title: "Inventory",
            type: "basic",
            link: "/apps/ecommerce/inventory",
          },
        ],
      },
      {
        id: "applications.mailbox",
        title: "Mailbox",
        type: "basic",
        icon: "heroicons_outline:mail",
        link: "/apps/mailbox",
        badge: {
          title: "27",
          style: "rounded",
          background: "rgba(0, 0, 0, 0.24)",
          color: "#FFFFFF",
        },
      },
      {
        id: "applications.tasks",
        title: "Tasks",
        type: "basic",
        icon: "heroicons_outline:check-circle",
        link: "/apps/tasks",
      },
    ],
  },
  {
    id: "others",
    title: "OTHERS",
    type: "group",
  },
  {
    id: "pages",
    title: "Pages",
    type: "aside",
    icon: "heroicons_outline:document-duplicate",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "user-interface",
    title: "User Interface",
    type: "aside",
    icon: "heroicons_outline:collection",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "navigation-features",
    title: "Navigation Features",
    type: "aside",
    icon: "heroicons_outline:menu",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const horizontalNavigation: TreoNavigationItem[] = [
  {
    id: "dashboards",
    title: "Dashboards",
    type: "group",
    icon: "heroicons_outline:home",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "applications",
    title: "Apps",
    type: "group",
    icon: "heroicons_outline:qrcode",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "pages",
    title: "Pages",
    type: "group",
    icon: "heroicons_outline:document-duplicate",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "user-interface",
    title: "UI",
    type: "group",
    icon: "heroicons_outline:collection",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: "navigation-features",
    title: "Misc",
    type: "group",
    icon: "heroicons_outline:menu",
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
