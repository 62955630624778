import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IAbstractProduct } from 'app/data/model/shop/product-abstract.interface';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ShopService } from '../shop.service';

@Component({
  selector: 'shop-cart-menue-modal',
  templateUrl: './shop-cart-menue-modal.component.html',
  styleUrls: ['./shop-cart-menue-modal.component.scss']
})
export class ShopCartMenueModalComponent implements OnInit {
  unreadCount: number;
  entrieCount = 0;

  constructor(
    private _dialogSelfRef: MatDialogRef<ShopCartMenueModalComponent>,
    private _shopService: ShopService,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  get cartProductList$(): Observable<IAbstractProduct[]> {
    return this._shopService.cartProducts$.pipe(tap(x => this.entrieCount = x.length));
  }

  get hasCartEntrie(): boolean {
    return this._shopService.hasEntries();
  }

  hasProductItemNotAnImage(productItem: IAbstractProduct): boolean {
    if (!productItem.imageSrc) {
        return true;
    }

    return productItem.imageSrc.length <= 0;
  }

  changeButtonCollection(event: 'add' | 'remove', productItem: IAbstractProduct): void {
    this._shopService.updateCartProductAmount(productItem, event);
  }

  openCheckoutDialog(): void {
    this._dialogSelfRef.close('checkout');
  }

  openCheckoutPage(): void {
    this._router.navigate(['/', 'apps', 'shop', 'checkout-mobile']); 
    this._dialogSelfRef.close();
  }

  removeCartProduct(productItem: IAbstractProduct): void {
    this._shopService.deleteCartProductItem(productItem);
  }

}
