import { cloneDeep } from "lodash-es";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WoocomerceService } from "app/core/woocomerce.service";
import { WoocommerceProduct } from "app/data/model/shop/woocommerce-product";
import {
  BehaviorSubject,
  Observable,
  Subject,
  TimeoutError,
  of,
  throwError,
} from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { IAbstractProduct } from "app/data/model/shop/product-abstract.interface";
import { environment } from "environments/environment";
import { CreateOrderRequest } from "app/data/model/shop/create-order-request";
import { Coupon } from "app/data/model/shop/coupon";
import { SpinnerService } from "app/shared/spinner/spinner.service";

@Injectable({
  providedIn: "root",
})
export class ShopService {
  private _cartProductsSubject: BehaviorSubject<IAbstractProduct[]> =
    new BehaviorSubject([]);
  private _remoteOpenPanel: Subject<void> = new Subject();
  private _cartProducts: IAbstractProduct[] = [];
  private _couponList: Coupon[] = [];
  private _woocommerceProductList: WoocommerceProduct[] = [];

  constructor(
    private _httpClient: HttpClient,
    private _wo: WoocomerceService,
    private _spinnerService: SpinnerService
  ) {
    this.initInstanceDataFromCache();
  }

  get productList$(): Observable<WoocommerceProduct[]> {
    const spinner = this._spinnerService.openSpinner(
      "Lädt die Produkt Liste ..."
    );

    const url =
      environment.restBaseUri + "wp-json/desnet-middleware/v1/product/all";
    return this._httpClient.get<WoocommerceProduct[]>(url).pipe(
      tap((response) => {
        this._woocommerceProductList = response;
        spinner.close();
      }),
      map((response) => {
        return response
          .filter((product) => product.purchasable !== false)
          .map((x) => {
            if (!x.amount) {
              if (x.attributes.length <= 0) {
                x.amount = 1;
                return x;
              }

              const minCount = x.attributes.find(
                (attributeItem) => attributeItem.name === "minCount"
              );
              if (!minCount) {
                x.amount = 1;
                return x;
              }

              if (isNaN(Number(minCount.options[0]))) {
                x.amount = 1;
                return x;
              }

              x.amount = Number(minCount.options[0]);
            }

            return x;
          });
      }),
      catchError((err) => {
        spinner.close();
        throw err;
      })
    );
  }

  get cartProducts$(): Observable<IAbstractProduct[]> {
    return this._cartProductsSubject.asObservable();
  }

  get remoteOpenPanel$(): Observable<void> {
    return this._remoteOpenPanel.asObservable();
  }

  get currentCouponList(): Coupon[] {
    return this._couponList;
  }

  public addProductItem(productItem: WoocommerceProduct): void {
    const indexOfProductItem: number = this._cartProducts.findIndex(
      (x) => x.productId === productItem.id
    );
    if (indexOfProductItem < 0) {
      this._cartProducts.push({
        attributes: productItem.attributes,
        amount: productItem.amount || 1,
        imageSrc:
          productItem.images.length > 0 ? productItem.images[0].src : "",
        name: productItem.name,
        price: productItem.price,
        productId: productItem.id,
        type: "PRODUCT",
        categories: productItem.categories || [],
      });

      this._cartProductsSubject.next(this._cartProducts);
    } else {
      const cloneItem: IAbstractProduct = cloneDeep(
        this._cartProducts[indexOfProductItem]
      );
      cloneItem.amount = cloneItem.amount + productItem.amount;
      this._cartProducts[indexOfProductItem] = cloneItem;
    }

    this._cartProductsSubject.next(this._cartProducts);
    localStorage.setItem("shopcart", btoa(JSON.stringify(this._cartProducts)));
    this._remoteOpenPanel.next();
  }

  public updateCartProductAmount(
    productItem: IAbstractProduct,
    methode: "add" | "remove"
  ): void {
    if (methode === "add") {
      const targetItemIndex: number = this._cartProducts.findIndex(
        (x) => x.productId === productItem.productId
      );
      const cloneCartProductItem: IAbstractProduct = cloneDeep(
        this._cartProducts[targetItemIndex]
      );
      cloneCartProductItem.amount = productItem.amount + 1;
      this._cartProducts[targetItemIndex] = cloneCartProductItem;
    } else {
      const targetItemIndex: number = this._cartProducts.findIndex(
        (x) => x.productId === productItem.productId
      );
      const cloneCartProductItem: IAbstractProduct = cloneDeep(
        this._cartProducts[targetItemIndex]
      );
      const minCount: number = this.getMinCountValue(cloneCartProductItem);

      cloneCartProductItem.amount = productItem.amount - 1;
      if (minCount <= -1) {
        if (cloneCartProductItem.amount <= 0) {
          this._cartProducts.splice(targetItemIndex, 1);
        } else {
          this._cartProducts[targetItemIndex] = cloneCartProductItem;
        }
      } else {
        if (cloneCartProductItem.amount < minCount) {
          this._cartProducts.splice(targetItemIndex, 1);
        } else {
          this._cartProducts[targetItemIndex] = cloneCartProductItem;
        }
      }
    }

    this._cartProductsSubject.next(this._cartProducts);

    if (this._cartProducts.length <= 0) {
      localStorage.removeItem("shopcart");
    }
  }

  public deleteCartProductItem(productItem: IAbstractProduct): void {
    const targetItemIndex: number = this._cartProducts.findIndex(
      (x) => x.productId === productItem.productId
    );
    if (targetItemIndex > -1) {
      const targetItem: IAbstractProduct = cloneDeep(
        this._cartProducts[targetItemIndex]
      );

      this._cartProducts.splice(targetItemIndex, 1);
      this._cartProductsSubject.next(this._cartProducts);

      if (this._cartProducts.length <= 0) {
        localStorage.removeItem("shopcart");
      }

      if (targetItem.type === "COUPON") {
        this._couponList = this._couponList.filter(
          (x) => x.id !== targetItem.productId
        );
      }
    }
  }

  public hasEntries(): boolean {
    return this._cartProducts.length > 0;
  }

  public getCurrentProductCartState(): IAbstractProduct[] {
    return this._cartProducts;
  }

  public getCurrentProductListState(): WoocommerceProduct[] {
    return this._woocommerceProductList;
  }

  public getProductDetailObject(
    productId: number
  ): WoocommerceProduct | undefined {
    return this._woocommerceProductList.find((x) => x.id === productId);
  }

  public initInstanceDataFromCache(): void {
    if (!localStorage.getItem("shopcart")) {
      return;
    }

    this._cartProducts = JSON.parse(atob(localStorage.getItem("shopcart")));
    this._cartProductsSubject.next(this._cartProducts);
  }

  public clearAllInstanceData(): void {
    this._cartProductsSubject.next([]);
    this._cartProducts = [];
    this._woocommerceProductList = [];
    this._couponList = [];
    localStorage.removeItem("shopcart");
  }

  // ToDo: Implmented an dto for response
  public sendOrderToWoocommerce(
    createOrderRequestData: CreateOrderRequest,
    userId: number,
    shouldSaveMetaData: boolean = false,
    userMetaData: any = {}
  ): Promise<any> {
    const requestData = {
      data: createOrderRequestData,
      saveDataAsUserMetData: shouldSaveMetaData,
      userId: userId,
      userMetaDataList: userMetaData,
    };
    return this._httpClient
      .post(
        environment.restBaseUri + "wp-json/desnet-middleware/v1/order",
        requestData
      )
      .pipe(
        catchError((err) => {
          if (err instanceof TimeoutError) {
            return this.checkIfOrderIsCreated(
              createOrderRequestData.billing.email
            );
          }

          return throwError(err);
        })
      )
      .toPromise();
  }

  public getCouponByCode(code: string): Promise<Coupon> {
    return this._httpClient
      .get<Coupon>(
        environment.restBaseUri +
          "wp-json/desnet-middleware/v1/coupon?code=" +
          code
      )
      .toPromise();
  }

  public isCouponExist(code: string): boolean {
    return (
      this._couponList.find((x) => x.code === code.toLowerCase()) !== undefined
    );
  }

  public setBillingCalculateWithCoupon(coupon: Coupon): void {
    if (coupon.discount_type === "fixed_cart") {
      const amount: number = parseFloat(coupon.amount.replace(/,/g, ""));

      this._cartProducts.push({
        attributes: [],
        amount: 1,
        imageSrc: "",
        name: "Coupon: " + coupon.code,
        price: -Math.abs(amount),
        productId: coupon.id,
        type: "COUPON",
        categories: [],
      });
      this._cartProductsSubject.next(this._cartProducts);
    } else if (coupon.discount_type === "fixed_product") {
      if (coupon.product_ids === undefined || coupon.product_ids === null) {
        throw new Error(
          "Der Coupon ist nicht auf ein oder mehrere Produkte spezifiziert. Bitte wende dich an das Konzept Weiss Headquarter."
        );
      }

      if (coupon.product_ids.length <= 0) {
        throw new Error(
          "Der Coupon ist nicht auf ein oder mehrere Produkte spezifiziert. Bitte wende dich an das Konzept Weiss Headquarter."
        );
      }

      const filterCartList = this._cartProducts.filter((x) => {
        if (x.type !== "PRODUCT") {
          return false;
        }

        return coupon.product_ids.includes(x.productId);
      });
      const amount: number =
        filterCartList.length > 0
          ? parseFloat(coupon.amount.replace(/,/g, "")) * filterCartList.length
          : parseFloat(coupon.amount.replace(/,/g, ""));

      this._cartProducts.push({
        attributes: [],
        amount: 1,
        imageSrc: "",
        name: "Coupon: " + coupon.code + " - Produkt",
        price: -Math.abs(amount),
        productId: coupon.id,
        type: "COUPON",
        categories: [],
      });
      this._cartProductsSubject.next(this._cartProducts);
    } else if (coupon.discount_type === "percent") {
      const calculateType = this.getCalculationType(coupon);
      let amount = 0;

      // Product
      if (calculateType === "PRODUCT") {
        // By product id
        const productIdList = coupon.product_ids;
        const filterCartList: IAbstractProduct[] = this._cartProducts.filter(
          (x) => {
            if (x.type !== "PRODUCT") {
              return false;
            }

            return productIdList.includes(x.productId);
          }
        );

        if (filterCartList.length <= 0) {
          throw new Error(
            "Die Produkte im Warenkorb sind nicht für diesen Coupon bestimmt."
          );
        }

        let summaryPrice = 0;
        filterCartList.forEach(
          (x) => (summaryPrice = summaryPrice + x.price * x.amount)
        );
        amount =
          (summaryPrice / 100) * parseFloat(coupon.amount.replace(/,/g, ""));
      } else if (calculateType === "CATEGORIE") {
        // By categorie
        const categorieIdList = coupon.product_categories;
        const filterCartList: IAbstractProduct[] = this._cartProducts.filter(
          (x) => {
            if (x.type !== "PRODUCT") {
              return false;
            }

            return (
              x.categories.filter((y) => categorieIdList.includes(y.id))
                .length > 0
            );
          }
        );

        if (filterCartList.length <= 0) {
          throw new Error(
            "Die Produkte im Warenkorb sind nicht für diesen Coupon bestimmt."
          );
        }

        let summaryPrice = 0;
        filterCartList.forEach(
          (x) => (summaryPrice = summaryPrice + x.price * x.amount)
        );
        amount =
          (summaryPrice / 100) * parseFloat(coupon.amount.replace(/,/g, ""));
      } else {
        const filterCartList: IAbstractProduct[] = this._cartProducts.filter(
          (x) => x.type === "PRODUCT"
        );
        let summaryPrice = 0;
        filterCartList.forEach(
          (x) => (summaryPrice = summaryPrice + x.price * x.amount)
        );
        amount =
          (summaryPrice / 100) * parseFloat(coupon.amount.replace(/,/g, ""));
      }

      this._cartProducts.push({
        attributes: [],
        amount: 1,
        imageSrc: "",
        name: "Coupon: " + coupon.code + " - Prozent",
        price: -Math.abs(amount),
        productId: coupon.id,
        type: "COUPON",
        categories: [],
      });
      this._cartProductsSubject.next(this._cartProducts);
    }

    this._couponList.push(coupon);
  }

  public removeCoupon(): void {
    this._couponList = [];
    this._cartProducts = this._cartProducts.filter((x) => x.type !== "COUPON");
    this._cartProductsSubject.next(this._cartProducts);
  }

  private getMinCountValue(productItem: IAbstractProduct): number {
    if (!productItem.attributes) {
      return -1;
    }

    if (productItem.attributes.length <= 0) {
      return -1;
    }

    const minCount = productItem.attributes.find(
      (attributeItem) => attributeItem.name === "minCount"
    );
    if (!minCount) {
      return -1;
    }

    if (isNaN(Number(minCount.options[0]))) {
      return -1;
    }

    return Number(minCount.options[0]);
  }

  public getMinAmountValue(productItem: WoocommerceProduct): number {
    if (productItem.amount !== undefined) {
      return productItem.amount;
    }

    if (productItem.attributes.length <= 0) {
      return 1;
    }

    const minCount = productItem.attributes.find(
      (attributeItem) => attributeItem.name === "minCount"
    );
    if (!minCount) {
      return 1;
    }

    if (isNaN(Number(minCount.options[0]))) {
      return 1;
    }

    return Number(minCount.options[0]);
  }

  public generateInvoice(orderId: number): Promise<void> {
    return this._httpClient
      .get<void>(
        environment.restBaseUri +
          `wp-json/desnet-middleware/v1/invoice/generate?id=${orderId}`
      )
      .toPromise();
  }

  private getCalculationType(
    coupon: Coupon
  ): "PRODUCT" | "CATEGORIE" | "ALL_PRODUCT" {
    if (coupon.product_ids !== undefined) {
      if (
        coupon.product_ids.length > 0 &&
        (coupon.product_categories === undefined ||
          coupon.product_categories === null)
      ) {
        // return CalculateTyp.PRODUCT;
        return "PRODUCT";
      } else if (
        coupon.product_ids.length > 0 &&
        coupon.product_categories.length <= 0
      ) {
        // return CalculateTyp.PRODUCT;
        return "PRODUCT";
      }
    }

    if (coupon.product_categories !== undefined) {
      if (
        coupon.product_categories.length > 0 &&
        (coupon.product_ids === undefined || coupon.product_ids === null)
      ) {
        // return CalculateTyp.CATEGORIE;
        return "CATEGORIE";
      } else if (
        coupon.product_categories.length > 0 &&
        coupon.product_ids.length <= 0
      ) {
        // return CalculateTyp.CATEGORIE;
        return "CATEGORIE";
      }
    }

    // return CalculateTyp.ALL_PRODUCT;
    return "ALL_PRODUCT";
  }

  private checkIfOrderIsCreated(email: string): Observable<any> {
    const requestData = {
      email,
    };
    return this._httpClient.post(
      environment.restBaseUri + "wp-json/desnet-middleware/v1/order/email/last",
      requestData
    );
  }
}
