import { ShopCartMenueModalComponent } from './../shop-cart-menue-modal/shop-cart-menue-modal.component';
import { CheckOutModalComponent } from './../check-out-modal/check-out-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { IAbstractProduct } from 'app/data/model/shop/product-abstract.interface';
import { Observable, Subscription } from 'rxjs';
import { ShopService } from '../shop.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'shop-cart-menue',
  templateUrl: './shop-cart-menue.component.html',
  styleUrls: ['./shop-cart-menue.component.scss']
})
export class ShopCartMenueComponent implements OnInit, OnDestroy {
  unreadCount: number;
  entrieCount = 0;

  // Private
  private _overlayRef: OverlayRef;
  private _subscriptionList: Subscription[] = [];

  @ViewChild('cartPanelButton')
  private _cartButton: MatButton;

  @ViewChild('cartPanel')
  private _cartPanel: TemplateRef<any>;

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {Overlay} _overlay
   * @param {ViewContainerRef} _viewContainerRef
   */
  constructor(
      private _changeDetectorRef: ChangeDetectorRef,
      private _overlay: Overlay,
      private _viewContainerRef: ViewContainerRef,
      private _dialog: MatDialog,
      private _shopService: ShopService,
      private _router: Router
  )
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    this._changeDetectorRef.markForCheck();
    this._subscriptionList[this._subscriptionList.length] = 
        this._shopService.remoteOpenPanel$.subscribe(() => this.openShopCartMenueModal());
    this._subscriptionList[this._subscriptionList.length] = 
        this._shopService.cartProducts$.subscribe(x => this.entrieCount = !x ? 0 : x.length);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
      // Dispose the overlay if it's still on the DOM
      if ( this._overlayRef )
      {
          this._overlayRef.dispose();
      }

      this._subscriptionList.forEach(x => {
          if (!x.closed) {
              x.unsubscribe();
          }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  get hasCartEntrie(): boolean {
      return this._shopService.hasEntries();
  }

  hasProductItemNotAnImage(productItem: IAbstractProduct): boolean {
      if (!productItem.imageSrc) {
          return true;
      }

      return productItem.imageSrc.length <= 0;
  }

  openCheckoutDialog(): void {
    this._dialog.open(CheckOutModalComponent, { maxWidth: '900px', height: 'auto' });
  }

  openShopCartMenueModal(): void {
    const shopCartMenue = this._dialog.open(ShopCartMenueModalComponent, { height: '80%' });

    shopCartMenue.afterClosed().subscribe(value => {
        if (!value) {
            return;
        }

        if (value === 'none') {
            return;
        } else if (value === 'checkout') {
            this.openCheckoutDialog();
        }
    });
  }
}
