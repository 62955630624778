import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { AuthService } from "app/core/auth/auth.service";
import {
  AuthUserData,
  BillingAddress,
  ShippingAddress,
  UserRole,
} from "app/data/model/auth/auth-response";
import {
  SelectOptions,
  countrySelectOptions,
} from "app/data/model/constant/country-select";
import { Coupon } from "app/data/model/shop/coupon";
import { CreateOrderRequest } from "app/data/model/shop/create-order-request";
import { IAbstractProduct } from "app/data/model/shop/product-abstract.interface";
import { FailMessageComponent } from "app/shared/fail-message/fail-message.component";
import { SpinnerService } from "app/shared/spinner/spinner.service";
import { SuccessMessageComponent } from "app/shared/success-message/success-message.component";
import { CheckOutModalComponent } from "./check-out-modal/check-out-modal.component";
import { ShopService } from "./shop.service";
import { Subscription } from "rxjs";

export class CheckOut {
  verticalStepperForm: FormGroup;
  shouldCopyFromShipping = true;

  private _cartProductList: IAbstractProduct[] = [];
  readonly countrySelectList: SelectOptions[] = countrySelectOptions;

  private subscriptions: Subscription[] = [];

  protected checkOutType: "SITE" | "MODAL" = "SITE";

  constructor(
    private _formBuilder: FormBuilder,
    private _shopService: ShopService,
    private _authService: AuthService,
    private _spinnerService: SpinnerService,
    private _dialogRef: MatDialog,
    private _dialogSelfRef?: MatDialogRef<CheckOutModalComponent>,
    private _router?: Router
  ) {}

  protected initInstance(): void {
    const authUserData: AuthUserData = this._authService.authUserData;
    this._cartProductList = this._shopService.getCurrentProductCartState();
    if (this.checkOutType === "SITE") {
      if (this._router && this._cartProductList.length <= 0) {
        this._router.navigate(["/", "dashboards", "finance"]);
      }
    }

    this.verticalStepperForm = this._formBuilder.group({
      step1: this._formBuilder.group({
        firstName: [
          authUserData.billing_adress?.first_name || "",
          Validators.required,
        ],
        lastName: [
          authUserData.billing_adress?.last_name || "",
          Validators.required,
        ],
        company: [
          authUserData.billing_adress?.company || "",
          Validators.required,
        ],
        street1: [
          authUserData.billing_adress?.street_and_housenumber || "",
          Validators.required,
        ],
        city: [authUserData.billing_adress?.city || "", Validators.required],
        postcode: [
          authUserData.billing_adress?.post_code || "",
          [Validators.required, Validators.maxLength(5)],
        ],
        country: [authUserData.billing_adress?.country || "DE"],
        email: [
          authUserData.billing_adress?.email || "",
          [Validators.required, Validators.email],
        ],
      }),
      step2: this._formBuilder.group({
        dataAreTheSame: [true],
        firstName: [
          authUserData.shipping_adress?.first_name || "",
          Validators.required,
        ],
        lastName: [
          authUserData.shipping_adress?.last_name || "",
          Validators.required,
        ],
        company: [
          authUserData.shipping_adress?.company || "",
          Validators.required,
        ],
        street1: [
          authUserData.shipping_adress?.street_and_housenumber || "",
          Validators.required,
        ],
        city: [authUserData.shipping_adress?.city || "", Validators.required],
        postcode: [
          authUserData.shipping_adress?.post_code || "",
          [Validators.required, Validators.maxLength(5)],
        ],
        country: [
          authUserData.shipping_adress?.country || "DE",
          Validators.required,
        ],
      }),
      step3: this._formBuilder.group({
        payOption: ["payOnBilling", Validators.required],
      }),
      step4: this._formBuilder.group({
        coupon: [""],
        saveMetaData: [
          this._authService.authUserData?.billing_adress !== undefined ||
          this._authService.authUserData?.shipping_adress !== undefined
            ? true
            : false,
        ],
      }),
    });

    // switch toggle billing data are also shipping data value change
    this.subscriptions[this.subscriptions.length] = this.verticalStepperForm
      .get("step2.dataAreTheSame")
      .valueChanges.subscribe(this.dataAreTheSameValueChange.bind(this));

    this.verticalStepperForm.get("step2.dataAreTheSame").setValue(true);
  }

  get fromData(): any {
    return this.verticalStepperForm.getRawValue();
  }

  get cartProductList(): IAbstractProduct[] {
    return this._cartProductList;
  }

  get summaryPriceWithoutTax(): number {
    let result = 0;
    this._cartProductList.forEach(
      (x) => (result = result + x.price * x.amount)
    );
    return result;
  }

  get summaryPriceWithoutTaxAndCoupons(): number {
    let result = 0;
    this._cartProductList.forEach((x) => {
      if (x.type === "PRODUCT") {
        result = result + x.price * x.amount;
      }
    });
    return result;
  }

  get summaryPriceWithTax(): number {
    let result = 0;
    this._cartProductList.forEach(
      (x) => (result = result + x.price * x.amount)
    );
    return this.shippingCost > 0
      ? (result + this.shippingCost) * 1.19
      : result * 1.19;
  }

  get shippingCost(): number {
    return this.summaryPriceWithoutTaxAndCoupons > 100 ? 0.0 : 5.0;
  }

  get taxPrice(): number {
    let calculatePrice = 0;
    if (this.shippingCost > 0) {
      calculatePrice =
        (this.shippingCost + this.summaryPriceWithoutTaxAndCoupons) * 1.19;
    } else {
      calculatePrice = this.summaryPriceWithoutTax * 1.19;
    }

    return this.shippingCost > 0
      ? calculatePrice -
          (this.shippingCost + this.summaryPriceWithoutTaxAndCoupons)
      : calculatePrice - this.summaryPriceWithoutTax;
  }

  get hasMerchantRights(): boolean {
    return this._authService.userHasRole([UserRole.MERCHANT]);
  }

  hasProductItemNotAnImage(productItem: IAbstractProduct): boolean {
    if (!productItem.imageSrc) {
      return true;
    }

    return productItem.imageSrc.length <= 0;
  }

  getPayMethodString(value: string): string {
    switch (value) {
      case "payOnBilling":
        return "Bezahlen per Rechnung";

      default:
        return "";
    }
  }

  async sendOrder(): Promise<void> {
    let spinner = this._spinnerService.openSpinner(
      "Bestellung wird erstellt ..."
    );

    try {
      let userId: number | null = null;
      if (this._authService.authUserData?.wp_id) {
        userId = this._authService.authUserData?.wp_id;
      }

      const filterProductList: IAbstractProduct[] =
        this._cartProductList.filter((x) => x.type === "PRODUCT");
      const filterCouponList: IAbstractProduct[] = this._cartProductList.filter(
        (x) => x.type === "COUPON"
      );
      const requestData: CreateOrderRequest = {
        payment_method: "bacs",
        payment_method_title: "Bezahlen per Rechnung",
        set_paid: false,
        billing: {
          first_name: this.fromData.step1.firstName,
          last_name: this.fromData.step1.lastName,
          address_1: this.fromData.step1.street1,
          address_2: "",
          city: this.fromData.step1.city,
          state: "",
          postcode: this.fromData.step1.postcode.toString(),
          country: this.fromData.step1.country,
          email: this.fromData.step1.email,
          phone: "",
          company: this.fromData.step1.company,
        },
        shipping: {
          first_name: this.fromData.step2.firstName,
          last_name: this.fromData.step2.lastName,
          address_1: this.fromData.step2.street1,
          address_2: "",
          city: this.fromData.step2.city,
          state: "",
          postcode: this.fromData.step2.postcode.toString(),
          country: this.fromData.step2.country,
          company: this.fromData.step2.company,
        },
        line_items:
          filterProductList.length > 0
            ? filterProductList.map((x) => ({
                product_id: x.productId,
                quantity: x.amount,
              }))
            : [],
        coupon_lines:
          filterCouponList.length > 0
            ? this._shopService.currentCouponList
                .filter(
                  (x) =>
                    filterCouponList.find((y) => y.productId === x.id) !==
                    undefined
                )
                .map((z) => ({ code: z.code }))
            : [],
        customer_id: userId,
        shipping_lines: [],
        status: "processing",
      };

      if (this.shippingCost > 0) {
        requestData.shipping_lines.push({
          method_id: "flat_rate",
          method_title: "DHL/Hermes",
          total: "5.00",
        });
      }

      // create order
      const response = await this._shopService.sendOrderToWoocommerce(
        requestData,
        userId
      );
      spinner.close();

      if (!response?.hasOrderCreated) {
        this._dialogRef.open(FailMessageComponent, {
          data: {
            message:
              "Es scheint dass evtl. Ihre Bestellung im System nicht erfasst wurde." +
              " Bitte prüfen Sie in der Bestell Historie nach oder kontakieren Sie Ihren" +
              " unseren Support im Konzept Weiss Headquarter.",
          },
        });
      }

      let shouldRefreshUserProfile: boolean = false;
      if (!userId) {
        spinner = this._spinnerService.openSpinner(
          "Userdaten werden aktualisiert ..."
        );
        await this._authService.updateWPUserId(response.wp_id);
        spinner.close();
        shouldRefreshUserProfile = true;
      }

      if (Object.keys(response.orderResponse).includes("invoiceUrl")) {
        spinner = this._spinnerService.openSpinner(
          "Rechnung wird erstellt ..."
        );
        await this._shopService.generateInvoice(response.orderResponse.id);
        spinner.close();
        window.open(response.orderResponse.invoiceUrl, "_blank");
      }

      this._shopService.clearAllInstanceData();
      this._dialogRef.open(SuccessMessageComponent, {
        data: {
          message:
            "Die Bestellung wurde erfolgreich eingereicht und befindet sich in Bearbeitung!",
        },
      });

      if (this.fromData.step4.saveMetaData) {
        const userMetaData = this.convertToUserMetaData();
        spinner = this._spinnerService.openSpinner(
          "Die Bestelldaten werden im Profile gespeichert ..."
        );

        const promiseList = [];
        promiseList.push(
          this._authService.sendUpdatedUserBillingAddress(userMetaData.billing)
        );
        promiseList.push(
          this._authService.sendUpdatedUserShippingAddress(
            userMetaData.shipping
          )
        );

        await Promise.all(promiseList);
        await this._authService.refreshUserProfile();
        spinner.close();
        this._dialogRef.open(SuccessMessageComponent, {
          data: {
            message: "Die Daten wurden erfolgreich im Profile gespeichert!",
          },
        });
      }

      if (!this.fromData.step4.saveMetaData && shouldRefreshUserProfile) {
        await this._authService.refreshUserProfile();
      }

      if (this.checkOutType === "MODAL") {
        this._dialogSelfRef.close();
      } else {
        this._router.navigate(["/", "dashboards", "finance"]);
      }
    } catch (error) {
      spinner.close();
      this._dialogRef.open(FailMessageComponent, {
        data: {
          message:
            "Ein Fehler ist bei der Erstellung der Bestellung aufgetreten!",
        },
      });
      console.error(error);
    }
  }

  getCoupon(): void {
    const couponCode: string = this.fromData.step4.coupon;
    if (couponCode) {
      const spinner = this._spinnerService.openSpinner(
        "Coupon wird überprüft ..."
      );
      this._shopService
        .getCouponByCode(couponCode.toLowerCase())
        .then((response) => {
          this.setCoupon(response);
        })
        .catch((err) =>
          this._dialogRef.open(FailMessageComponent, {
            data: { message: err.message },
          })
        )
        .finally(() => {
          spinner.close();
        });
    }
  }

  removeCoupon(): void {
    this._shopService.removeCoupon();
  }

  copyBillingDataIntoShippingData(): void {
    this.verticalStepperForm
      .get("step2.firstName")
      .setValue(this.fromData.step1.firstName || "");
    this.verticalStepperForm
      .get("step2.lastName")
      .setValue(this.fromData.step1.lastName || "");
    this.verticalStepperForm
      .get("step2.company")
      .setValue(this.fromData.step1.company || "");
    this.verticalStepperForm
      .get("step2.street1")
      .setValue(this.fromData.step1.street1 || "");
    this.verticalStepperForm
      .get("step2.city")
      .setValue(this.fromData.step1.city || "");
    this.verticalStepperForm
      .get("step2.postcode")
      .setValue(this.fromData.step1.postcode || "");
    this.verticalStepperForm
      .get("step2.country")
      .setValue(this.fromData.step1.country || "");
  }

  dataAreTheSameValueChange(value: boolean): void {
    if (!value) {
      this.verticalStepperForm.get("step2.firstName").enable();
      this.verticalStepperForm.get("step2.lastName").enable();
      this.verticalStepperForm.get("step2.company").enable();
      this.verticalStepperForm.get("step2.street1").enable();
      this.verticalStepperForm.get("step2.city").enable();
      this.verticalStepperForm.get("step2.postcode").enable();
      this.verticalStepperForm.get("step2.country").enable();
    } else {
      this.verticalStepperForm.get("step2.firstName").disable();
      this.verticalStepperForm.get("step2.lastName").disable();
      this.verticalStepperForm.get("step2.company").disable();
      this.verticalStepperForm.get("step2.street1").disable();
      this.verticalStepperForm.get("step2.city").disable();
      this.verticalStepperForm.get("step2.postcode").disable();
      this.verticalStepperForm.get("step2.country").disable();

      this.copyBillingDataIntoShippingData();
    }
  }

  protected unsubscribeSubscriptions(): void {
    this.subscriptions.forEach((subscriptions) => {
      if (!subscriptions.closed) subscriptions.unsubscribe();
    });
  }

  private setCoupon(coupon: Coupon): void {
    if (this._shopService.isCouponExist(coupon.code)) {
      this._dialogRef.open(FailMessageComponent, {
        data: {
          message: "Der Coupon ist bereits hier in der Bestellung hinterlegt.",
        },
      });
      return;
    }

    try {
      this._shopService.setBillingCalculateWithCoupon(coupon);
    } catch (error) {
      this._dialogRef.open(FailMessageComponent, {
        data: { message: error.message },
      });
    }
  }

  private convertToUserMetaData(): {
    billing: BillingAddress;
    shipping: ShippingAddress;
  } {
    return {
      billing: {
        first_name: this.fromData.step1.firstName || "",
        last_name: this.fromData.step1.lastName || "",
        company: this.fromData.step1.company || "",
        street_and_housenumber: this.fromData.step1.street1 || "",
        city: this.fromData.step1.city || "",
        post_code: this.fromData.step1.postcode || 0,
        country: this.fromData.step1.country || "",
        email: this.fromData.step1.email || "",
      },
      shipping: {
        first_name: this.fromData.step2.firstName || "",
        last_name: this.fromData.step2.lastName || "",
        company: this.fromData.step2.company || "",
        street_and_housenumber: this.fromData.step2.street1 || "",
        city: this.fromData.step2.city || "",
        post_code: this.fromData.step2.postcode || 0,
        country: this.fromData.step2.country || "",
      },
    };
  }
}
