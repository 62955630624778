import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { KwAppConfig, KwAppConfigKey } from "./models/app-config.models";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { first, map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  private _configSubject: BehaviorSubject<KwAppConfig> = new BehaviorSubject(
    {}
  );

  constructor(private _httpClient: HttpClient) {}

  get kwConfigData$(): Observable<KwAppConfig> {
    return this._configSubject.asObservable();
  }

  /**
   * Load and return the application config which cames from the KW Account Service
   *
   * @returns {Observable<KwAppConfig>}
   */
  public loadAppConfigFromBackend$(): Observable<KwAppConfig> {
    return this._httpClient
      .get<KwAppConfig>(environment.accountServiceUri + "api/config/collection")
      .pipe(
        map((kwConfigData) => this.mapConfigCollection(kwConfigData) || {}),
        tap(this.updateConfigData.bind(this)),
        first()
      );
  }

  /**
   * Update kw app config behavior subject
   *
   * @returns {void}
   */
  private updateConfigData(configData: KwAppConfig): void {
    this._configSubject.next(configData);
  }

  /**
   * Update kw app config behavior subject
   *
   * @returns {void}
   */
  private mapConfigCollection(configData: KwAppConfig): KwAppConfig {
    const mappedConfigCollection = {};

    Object.keys(configData).forEach((key) => {
      switch (key) {
        case KwAppConfigKey.GENERATE_GIFT_VOUCHER_PDF_DOCUMENT:
        case KwAppConfigKey.SHOW_STATISTIC_IN_UI:
        case KwAppConfigKey.ENABLE_ONLINE_SHOP:
          const configBoolValue = Boolean(Number(configData[key]));

          mappedConfigCollection[key] = configBoolValue;
          break;

        default:
          mappedConfigCollection[key] = configData[key];
          break;
      }
    });

    return mappedConfigCollection;
  }
}
