import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { FailMessageComponent } from "app/shared/fail-message/fail-message.component";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private _dialogRef: MatDialog,
    private _authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = "";
        if (!error.error) {
          const errorObject: { message: string } = {
            message: error.message,
          };

          console.error("this is client side error", error);
          this._dialogRef.open(FailMessageComponent, { data: errorObject });
        } else if (error.error instanceof ErrorEvent) {
          const errorObject: { message: string } = {
            message: error.error.message,
          };

          console.error("this is client side error", error);
          this._dialogRef.open(FailMessageComponent, { data: errorObject });
        } else {
          let errorObject: { message: string; code?: number } = { message: "" };
          errorMsg = error.message;

          if (error.status === 401) {
            errorObject = {
              message:
                "Zugriff verweigert. Sie sind nicht angemeldet. Meldung vom Server: " +
                error.error.message,
              code: 401,
            };

            this._authService.resetInstance();
          } else if (error.status === 403) {
            errorObject = {
              message:
                "Zugriff verweigert. Für diesen Bereich hast du nicht die Berechtigung. Meldung vom Server: " +
                error.error.message,
              code: 403,
            };
          } else {
            errorObject = {
              message:
                "Es ist beim Server ein Fehler aufgetreten. Meldung vom Server: " +
                error.error.message,
              code: error.status,
            };
          }

          console.error("this is server side error", error);
          this._dialogRef.open(FailMessageComponent, { data: errorObject });
        }

        return throwError(error);
      })
    );
  }
}
