import { User } from "app/layout/common/user/user.types";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { combineLatest, Subject, Subscription } from "rxjs";
import { first, takeUntil, tap } from "rxjs/operators";
import { TreoMediaWatcherService } from "@treo/services/media-watcher";
import { TreoNavigationService } from "@treo/components/navigation";
import { AuthService } from "app/core/auth/auth.service";
import { FilterHelper } from "app/core/helper/filter-helper";
import { cloneDeep } from "lodash";
import { AppConfigService } from "app/core/config/app-config.service";
import {
  KwAppConfig,
  KwAppConfigKey,
} from "app/core/config/models/app-config.models";

@Component({
  selector: "classy-layout",
  templateUrl: "./classy.component.html",
  styleUrls: ["./classy.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  data: any;
  isScreenSmall: boolean;
  appConfigCollection: KwAppConfig = {};
  readonly appConfigEnum = KwAppConfigKey;

  // Private
  private _unsubscribeAll: Subject<any>;
  private _appConfigSubscription: Subscription | undefined;

  /**
   * Constructor
   *
   * @param {ActivatedRoute} _activatedRoute
   * @param {Router} _router
   * @param {TreoMediaWatcherService} _treoMediaWatcherService
   * @param {TreoNavigationService} _treoNavigationService
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _treoMediaWatcherService: TreoMediaWatcherService,
    private _treoNavigationService: TreoNavigationService,
    private _appConfigService: AppConfigService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }
  private _appConfig$ = this._appConfigService.kwConfigData$.pipe(
    tap((appConfig) => (this.appConfigCollection = appConfig))
  );

  get userProfile(): User | undefined {
    return this._authService.userMap;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the resolved route data
    combineLatest([
      this._activatedRoute.data,
      this._appConfig$.pipe(first()),
    ]).subscribe(([data, appConfig]) => {
      const cloneData: Data = cloneDeep(data.initialData);
      if (cloneData.navigation) {
        cloneData.navigation.default = FilterHelper.getNavigationItmeByUserRole(
          cloneData.navigation.default,
          this._authService.authUserData.role,
          appConfig
        );
      }

      this.data = cloneData;
    });

    // Subscribe to media changes
    this._treoMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the breakpoint is 'lt-md'
        this.isScreenSmall = matchingAliases.includes("lt-md");
      });

    this._appConfigSubscription = this._appConfig$.subscribe();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    if (!this._appConfigSubscription) return;
    if (!this._appConfigSubscription.closed)
      this._appConfigSubscription.unsubscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param key
   */
  toggleNavigation(key): void {
    // Get the navigation
    const navigation = this._treoNavigationService.getComponent(key);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}
