import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  name: "moment",
})
export class MomentPipe implements PipeTransform {
  transform(value: string | undefined | null, format: string): string {
    if (value === undefined || value === null) {
      return "-";
    }

    return moment(value).format(format);
  }
}
